import React from "react";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

export default function SliderArrow(props) {
  const { className, style, onClick, buttonIcon } = props;
  return (
    <button
      className={className}
      style={{ ...style }}
      onClick={onClick}
    >
      <i className={buttonIcon}></i>
    </button>
  );
}

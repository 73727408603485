import { excludeNodeEnv } from './DomHelper'

export const fullStory = async (myBock, cart) => {

  if(excludeNodeEnv() === true) {

    const {
      isAuthenticated,
      name,
      email
    } = myBock

    const {
      items,
      subtotal
    } = cart

    const appendCore = async () => {
      let script = document.createElement("script");

      let innerHtml = ` window['_fs_debug'] = false;
                        window['_fs_host'] = 'fullstory.com';
                        window['_fs_script'] = 'edge.fullstory.com/s/fs.js';
                        window['_fs_org'] = 'E9P0R';
                        window['_fs_namespace'] = 'FS';
                        (function(m,n,e,t,l,o,g,y){
                            if (e in m) {if(m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');} return;}
                            g=m[e]=function(a,b,s){g.q?g.q.push([a,b,s]):g._api(a,b,s);};g.q=[];
                            o=n.createElement(t);o.async=1;o.crossOrigin='anonymous';o.src='https://'+_fs_script;
                            y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y);
                            g.identify=function(i,v,s){g(l,{uid:i},s);if(v)g(l,v,s)};g.setUserVars=function(v,s){g(l,v,s)};g.event=function(i,v,s){g('event',{n:i,p:v},s)};
                            g.anonymize=function(){g.identify(!!0)};
                            g.shutdown=function(){g("rec",!1)};g.restart=function(){g("rec",!0)};
                            g.log = function(a,b){g("log",[a,b])};
                            g.consent=function(a){g("consent",!arguments.length||a)};
                            g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)};
                            g.clearUserCookie=function(){};
                            g.setVars=function(n, p){g('setVars',[n,p]);};
                            g._w={};y='XMLHttpRequest';g._w[y]=m[y];y='fetch';g._w[y]=m[y];
                            if(m[y])m[y]=function(){return g._w[y].apply(this,arguments)};
                            g._v="1.3.0";
                        })(window,document,window['_fs_namespace'],'script','user');`

      script.innerHTML = innerHtml

      await document.body.appendChild(script);
    }

    const appendUser = async () => {
      let script = document.createElement("script");

      let innerHtml = ` FS.identify('${name}', {
                          displayName: '${name}',
                          email: '${email}',
                          cartCount_int: '${items}',
                          cartSubtotal_str: '${subtotal}'
                        });`

      script.innerHTML = innerHtml

      await document.body.appendChild(script);
    }


    await appendCore()
    if(isAuthenticated) await appendUser()

  }
  // const brDom = typeof document !== `undefined` && typeof window !== `undefined` ? true : false;
  //
  // if(brDom) {

    // const appendDependee = async () => {
    //   let script = document.createElement("script");
    //   script.src = "https://chat-assets.frontapp.com/v1/chat.bundle.js";
    //   script.async = true;
    //
    //   await document.body.appendChild(script);
    // }
    //
    // const appendDepender = async () => {
    //   let script = document.createElement("script");
    //
    //   let innerHtml = "window.FrontChat('init', {chatId: '55851636b5a48769cb171ba95952e69d', useDefaultLauncher: true});"
    //
    //   if(myBock.isAuthenticated === true){
    //
    //     const {
    //       email,
    //       name
    //     } = myBock;
    //
    //     const hmac = crypto.createHmac('sha256', `${process.env.GATSBY_FRONTAPP_CHAT_VERIFICATION_SECRET}`);
    //     const userHash = hmac.update(email).digest('hex');
    //
    //     innerHtml = `window.FrontChat('init', {chatId: '55851636b5a48769cb171ba95952e69d', useDefaultLauncher: true, email: '${email}', userHash: '${userHash}' });`
    //   }
    //
    //   script.innerHTML = innerHtml
    //   script.async = true;
    //
    //   await document.body.appendChild(script);
    // }
    //
    // await appendDependee();
    //
    // setTimeout(async function(){
    //   await appendDepender();
    // }, 5000);

  // }

}

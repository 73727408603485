import React from 'react'
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import { makeStyles } from '@material-ui/core/styles'

function AlertBody(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}))

export default function Alert(props) {

  const { state, updateAlert } = props

  const {
    active,
    variant,
    body
  } = state

  const classes = useStyles()
  // const [open, setOpen] = React.useState(false)

  const handleClick = () => {
    // setOpen(true)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    updateAlert()
  };

  return (
    <div className={classes.root}>
      <Snackbar open={active} autoHideDuration={6000} onClose={handleClose}>
        <AlertBody severity={variant} onClose={handleClose}>
          { body }
        </AlertBody>
      </Snackbar>
    </div>
  )
}

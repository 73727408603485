import React, {useRef, useState, Suspense, useEffect} from 'react';
import { UnsignedByteType, PMREMGenerator, sRGBEncoding, LinearToneMapping } from 'three';
import * as THREE from 'three'
import { Canvas, useFrame, useLoader, useThree } from 'react-three-fiber';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js'

export const Environment = ({ loadHdriComplete, setLoadHdriComplete  }) => {
  const { gl, scene } = useThree()
  const [cubeMap, setCubeMap] = useState()
  // const [loadHdriComplete, setLoadHdriComplete] = useState(false)

  // gl.gammaInput = true
  // gl.gammaOutput = true;
  // gl.toneMapping = LinearToneMapping

  const HdrFilePath = 'https://scripts.source.xyz/js/hdr/studio007.hdr'

  useEffect(()=>{
    // console.log('hdr load')
    if (!loadHdriComplete){
      new RGBELoader()
      .setDataType(THREE.UnsignedByteType)
      .load(/*HdrFile*/ HdrFilePath , function(texture) {
        setCubeMap(texture)
        setLoadHdriComplete(true)
        //console.log('hdr loaded')
      });
    }
  },[loadHdriComplete])


  useEffect(()=>{
   // console.log('check scene.environment', scene.environment)
    if(scene.environment === null){
      if (cubeMap && scene){
        const gen = new THREE.PMREMGenerator(gl)
        gen.compileEquirectangularShader()
        const hdrCubeRenderTarget = gen.fromCubemap(cubeMap)
        cubeMap.dispose()
        gen.dispose()
        scene.environment = hdrCubeRenderTarget.texture
        // console.log('hdr assigned')
        // console.log('scene.environment assigned', scene.environment)
        return () => {
        //  scene.environment = scene.background = null
        }
      }
    }
  },[cubeMap, scene.environment])

  return null
}

export const Lighting = () => {
  const hemilight = useRef();
  const {gl} = useThree();
  gl.getContext();

  // Renderer Setup
  gl.physicallyCorrectLights = true;
  //gl.gammaOutput = true;
  gl.gammaFactor = 1;
  gl.outputEncoding = sRGBEncoding;

  return (
    <>
      <hemisphereLight intensity={0.2} />
      <spotLight position={[200, 0, 0]} intensity={1400} />
      <spotLight position={[-200, 0, 0]} intensity={1400} />
    </>
  );
};

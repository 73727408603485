import React, { useContext } from 'react';
import StateContext from '../../context/StateContext'
import GatsbyLink from 'gatsby-link';
import Select from 'react-select';
import { navigate } from "gatsby";

export default function ConfigureActions({
  array,
  arrayItemKey,
  arrayItemTitle,
  productId
 }){

   const { updateModal } = useContext(StateContext)

   let parts_returned = array.filter(item => item._b === 'fixture').map((item) => {
     let itemKey = item[arrayItemKey],
         itemTitle = item[arrayItemTitle],
         itemParent = productId;

     return ({
       value: itemKey,
       label: itemTitle,
       'data-parent-node': itemParent
     })

   });

   let part_select = <Select
     options={parts_returned}
     onChange={(v) => onSelect(v)}
     className="ccnConfigureActionsSelect"
     captureMenuScroll={false}
     menuShouldBlockScroll={false}
     // menuIsOpen={true}
     classNamePrefix="ccnConfigureActions"
   />

 const onSelect = (v) => {

   updateModal(false)

   let target = v.value

   if(target){
     let itemName = v.label
     let itemNode = v['data-parent-node']
     let itemConf = target
     let itemConfStringArgs = 'f:'+itemConf+';node:'+itemNode+';cp:overview'
     let itemConfString = '/create?b=' + Buffer.from(itemConfStringArgs).toString('base64');
     navigate(itemConfString)
   }
 }

  return (
    <div class="ccnConfigurePartSelect">
      <div class="label">Configure</div>
      <div class="value">
        {part_select}
      </div>
    </div>
  );
}

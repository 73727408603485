import React, {useRef, useEffect, useState} from 'react'
import { render } from 'react-three-fiber';
import { excludeNodeEnv } from '../../helpers/DomHelper'
import ThreeConfigurator from './src/components/ThreeConfigurator'

const ThreeJs = (props) => {
    const { model3d } = props;
    const [jsonModelSame, setJsonModelSame] = useState(true)
    const [savedIncomingJson, setSavedIncomingJson] = useState()
    const [incomingJson, setIncomingJson] = useState(false)
    const [isIncomingFirstJson, setIsFirstIncomingJson] = useState(true)
    const [rerender, setRerender] = useState(true)

    // Check if scene 3D componant is mounted
    useEffect(() =>{
        // console.log('scene 3d Mounted')
        return () => {
        // console.log('scene3d Unmounted')
         }
    },[])

    useEffect(() =>{
        if (model3d){
            setIncomingJson(JSON.parse(model3d))
        }
    },[model3d])

    const deepEqual = (object1, object2) => {
        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);

        if (keys1.length !== keys2.length) {
          return false;
        }

        for (const key of keys1) {
          const val1 = object1[key];
          const val2 = object2[key];
          const areObjects = isObject(val1) && isObject(val2);
          if (
            areObjects && !deepEqual(val1, val2) ||
            !areObjects && val1 !== val2
          ) {
            return false;
          }
        }

        return true;
    }

    const isObject = (object) => {
        return object != null && typeof object === 'object';
      }

    useEffect(()=>{
        if (incomingJson){
            // If the there is not data saved yet, save the first incoming json
            if (isIncomingFirstJson && savedIncomingJson === undefined){
                setIsFirstIncomingJson(false)
                setSavedIncomingJson(incomingJson)
                setRerender(true)
                return

            // If the incoming objects model property is not the same as the saved data, rerender
            }else if (!deepEqual(incomingJson.model, savedIncomingJson.model)){
                setRerender(false)
                setIncomingJson(false)
                setSavedIncomingJson(incomingJson)
                return

             // If the incoming data is the same as the saved data, keep rendering
            } else if (deepEqual(incomingJson.model, savedIncomingJson.model)) {
                setRerender(true)
                setSavedIncomingJson(incomingJson)
                return
            }
        }
    },[incomingJson])

    useEffect(() => {
        if (!incomingJson && !isIncomingFirstJson && !rerender){
            setTimeout(function(){ setIncomingJson(JSON.parse(model3d)); }, 500);
            setRerender(true)
        }
    },[incomingJson])


    if (incomingJson && excludeNodeEnv() === true) {
      return (
        <div className="three-container-inner">
          <ThreeConfigurator
              ThreeJsJson={incomingJson}
          />
        </div>
          );
      } else {
          return null
      }
};

export default ThreeJs;

const baseUrl = 'https://api.source.xyz'
// const baseUrl = 'https://cocoon-bock2018.pantheonsite.io';

export const sessToken = async () => {
  let response = await fetch(`${baseUrl}/session/token`,{
    method: 'GET',
    credentials: 'include'
  });
  let data = await response.text()
  return data;
}

export const getCart = async (csrfToken) => {
  let response = await fetch(`${baseUrl}/api/product_builder/get_cart`,{
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken
    },
  });
  let data = await response.json()
  return data;
}

export const myBock = async (csrfToken) => {
  let response = await fetch(`${baseUrl}/api/product_builder/my_bock`,{
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken
    },
  });
  let data = await response.json()
  return data;
}

export const myOrders = async (csrfToken) => {
  let response = await fetch(`${baseUrl}/api/product_builder/order_history?_format=json`,{
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken
    },
  });
  let data = await response.json()
  return data;
}

export const addToCart = async ({
  token: csrfToken,
  handle: productHandle = null,
  sku: sku = null,
  quantity: quantity = 1
}) => {

  let itemsToAdd = [];

  for(var i=0; i < quantity; i++){

    let addItem = {
      "sku": sku,
    }

    if(productHandle !== null) {
      addItem = {
        "handle": productHandle,
      }
    }

    itemsToAdd.push(addItem);
  }

  let response = await fetch(`${baseUrl}/api/product_builder/add_to_cart?_format=json`,{
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken
    },
    body: JSON.stringify(itemsToAdd)
  });
  let data = await response.json()
  return data;
}

export const updateCartItemQuantity = async (csrfToken, cartItemId, finalQuantity) => {
  let response = await fetch(`${baseUrl}/api/product_builder/update_cart?_format=json`,{
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken
    },
    body: JSON.stringify([
      {
        "id": cartItemId,
        "quantity": finalQuantity,
      }
    ])
  });
  let data = await response.json()
  return data;
}

export const pushCartToCheckout = async (csrfToken, couponCode = null) => {

  let response = await fetch(`${baseUrl}/api/product_builder/push_cart?_format=json`,{
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken
    },
    body: JSON.stringify({
      "code": couponCode,
    })
  });
  let data = await response.json()

  return data;
}

export const applyCoupon = async (csrfToken, couponCode = null) => {

  let response = await fetch(`${baseUrl}/api/product_builder/apply_coupon?_format=json`,{
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken
    },
    body: JSON.stringify({
      "code": couponCode,
    })
  });
  let data = await response.json()

  // console.log(data)
  return data;
}

export const getBuilds = async (csrfToken, offset) => {
  let response = await fetch(`${baseUrl}/api/product_builder/get_builds/${offset}?_format=json&pk=FL1`,{
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken
    },
  });
  let data = await response.json()
  return data.products;
}

export const getBuild = async (csrfToken, handle) => {
  let response = await fetch(`${baseUrl}/api/product_builder/get_product/${handle}`,{
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken
    },
  });
  let data = await response.json()
  return data;
}

export const searchBuilds = async (csrfToken, {
    searchMethod: method,
    fixtures: fixtures,
    lamping: lamp_types,
    mounting: mounting
  }) => {
  let response = await fetch(`${baseUrl}/api/product_builder/search_builds?_format=json`,{
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken
    },
    body: JSON.stringify({
      "method": method,
      "fixture": fixtures,
      "lamp_type": lamp_types,
      "mounting": mounting
    })
  });
  let data = await response.json()
  return data.results;
}

export const submitWebform = async (csrfToken, webformId, webformFields = []) => {
  let response = await fetch(`${baseUrl}/webform_rest/submit?_format=json`,{
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken
    },
    body: JSON.stringify({
      "webform_id": webformId,
      ...webformFields
    })
  });
  let data = await response.json()
  return data;
}

import crypto from 'crypto'

export const chatWidget = async (myBock) => {

  const brDom = typeof document !== `undefined` && typeof window !== `undefined` ? true : false;

  if(brDom) {

    const appendDependee = async () => {
      let script = document.createElement("script");
      script.src = "https://chat-assets.frontapp.com/v1/chat.bundle.js";
      script.async = true;

      await document.body.appendChild(script);
    }

    const appendDepender = async () => {
      let script = document.createElement("script");

      let innerHtml = "window.FrontChat('init', {chatId: '55851636b5a48769cb171ba95952e69d', useDefaultLauncher: true});"

      if(myBock.isAuthenticated === true){

        const {
          email,
          name
        } = myBock;

        const hmac = crypto.createHmac('sha256', `${process.env.GATSBY_FRONTAPP_CHAT_VERIFICATION_SECRET}`);
        const userHash = hmac.update(email).digest('hex');

        innerHtml = `window.FrontChat('init', {chatId: '55851636b5a48769cb171ba95952e69d', useDefaultLauncher: true, email: '${email}', userHash: '${userHash}' });`
      }

      script.innerHTML = innerHtml
      script.async = true;

      await document.body.appendChild(script);
    }

    await appendDependee();

    setTimeout(async function(){
      await appendDepender();
    }, 5000);

  }

}

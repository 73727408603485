import React, { useState, useContext } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Grid from '@material-ui/core/Grid'
import { Flex, Box, Image, Text } from 'rebass'
import StateContext from '../../context/StateContext'
import Cart from './Cart'
import LoaderCenterHorizontal from '../Commons/LoaderCenterHorizontal'
import styled, { css } from 'styled-components'
import { navigate } from 'gatsby'

const BtnContinue = styled('a')`
 &&& {
    display: block;
    padding: 13px 0;
    border: 2px solid #222;
    background: #222;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    &:hover {
      color: #222;
      background: transparent;
    }
  }
`
const BtnBuy = styled('a')`
  &&& {
    display: block;
    padding: 13px 0;
    color: #72bb72;
    border: 2px solid #72bb72;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    &:hover {
      color: #fff;
      background: #72bb72;
    }
  }
`

const TickIcon = props => (
  <div className={`ccnSuccessLg`}></div>
)

const CartIcon = props => (
  <div className={`ccnCartLg`}></div>
)

const CartModal = props => {

  const [isCheckingOut, setIsCheckingOut] = useState(false)

  const {
    cartModal
  } = props

  const {
    active,
    title,
    icon
  } = cartModal

  const {
    updateCartModal,
    pushCartToCheckout,
    cart
  } = useContext(StateContext)

  const {
    items
  } = cart

  // const {
  //   package_code
  // } = globalPackage

  const handleClose = () => {
    updateCartModal(false)
  }

  const gotoCheckout = async () => {
    await setIsCheckingOut(true)
    navigate('/cart')
    handleClose()
  }

  const variantIcon = {
    tick: TickIcon,
    cart: CartIcon
  }

  let modalTitle = `Your Cart`,
      modalIcon = `cart`

  if(title !== null) modalTitle = title
  if(icon !== null) modalIcon = icon

  if(cart && cart.items && cart.items.length === 0) {
    modalTitle = `There are no items in your cart`
  }

  // console.log(cart)
  // console.log(package_code)

  const Icon = variantIcon[modalIcon]

  return (
    <Dialog
      open={active}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={'md'}
      className="ccnModalCartSuccess"
      scroll={"body"}
      style={{maxWidth: 800,margin: `auto`}}
    >
      {isCheckingOut && <LoaderCenterHorizontal inlineOverlay /> }
      <Box width={1} p={[`30px 40px 60px`]} className="ccnModalCartSuccessInner">
        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close" style={{width: '50px', margin: '10px auto', position: 'absolute', top: '0', right: '10px', zIndex: '1'}}>
          <CloseIcon />
        </IconButton>
        <Box className="modalCartSuccessBody" mb={[`30`]}>
          <Grid container justify={`center`}>
            <Grid item xs={12} md={8}>
              <Flex mt={[20]} mb={[40]} alignItems={`center`} justifyContent={`center`}>
                <Icon />
                <Text as={`h3`} pl={20}>{ modalTitle }</Text>
              </Flex>
            </Grid>
          </Grid>
          <Cart />
        </Box>

        {cart && cart.items && cart.items.length > 0 &&
          <Grid container justify="center">
            <Grid item xs={12} md={8}>
              <Flex justifyContent="center" alignItems="center" mt={20} mb={40}>
                <Text style={{fontWeight: 900, fontSize: 12}}>Subtotal:</Text>
                <Text pl={15} fontSize={30} style={{fontWeight: 600}}>${cart.subtotal}</Text>
              </Flex>
              <Grid container justify={`center`} spacing={1}>
                <Grid item xs={12} md={6}>
                  <BtnContinue onClick={handleClose}>Continue shopping</BtnContinue>
                </Grid>
                <Grid item xs={12} md={6}>
                  <BtnBuy onClick={gotoCheckout}>View Cart</BtnBuy>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      </Box>
    </Dialog>
  );
};

export default CartModal;

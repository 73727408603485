import React from 'react';
import {useTransition, a} from 'react-spring';
import LoaderCenterHorizontal from './LoaderCenterHorizontal';
import '../style/loader.css';

const Loading = ({functionsAllLoaded}) => {
  const props = useTransition(functionsAllLoaded, null, {
    from: {opacity: 1},
    leave: {opacity: 0},
  });

  return props.map(
    ({item: functionsAllLoaded, key, props: {opacity}}) =>
      !functionsAllLoaded && (
        <a.div className="donut-3d-cont" key={key} style={{opacity}}>
          {/*<a.div className="donut-3d"></a.div>*/}
          <LoaderCenterHorizontal inline />
        </a.div>
      )
  );
};

export default Loading;

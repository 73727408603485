// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-build-jsx": () => import("./../../../src/pages/build.jsx" /* webpackChunkName: "component---src-pages-build-jsx" */),
  "component---src-pages-create-js": () => import("./../../../src/pages/create.js" /* webpackChunkName: "component---src-pages-create-js" */),
  "component---src-templates-cart-index-jsx": () => import("./../../../src/templates/cart/index.jsx" /* webpackChunkName: "component---src-templates-cart-index-jsx" */),
  "component---src-templates-catalog-catalog-jsx": () => import("./../../../src/templates/catalog/Catalog.jsx" /* webpackChunkName: "component---src-templates-catalog-catalog-jsx" */),
  "component---src-templates-category-category-jsx": () => import("./../../../src/templates/category/Category.jsx" /* webpackChunkName: "component---src-templates-category-category-jsx" */),
  "component---src-templates-inspiration-inspiration-masonry-page-jsx": () => import("./../../../src/templates/inspiration/InspirationMasonryPage.jsx" /* webpackChunkName: "component---src-templates-inspiration-inspiration-masonry-page-jsx" */),
  "component---src-templates-page-standard-page-jsx": () => import("./../../../src/templates/page/StandardPage.jsx" /* webpackChunkName: "component---src-templates-page-standard-page-jsx" */),
  "component---src-templates-policy-index-jsx": () => import("./../../../src/templates/policy/index.jsx" /* webpackChunkName: "component---src-templates-policy-index-jsx" */),
  "component---src-templates-product-product-page-jsx": () => import("./../../../src/templates/product/ProductPage.jsx" /* webpackChunkName: "component---src-templates-product-product-page-jsx" */)
}


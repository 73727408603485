import React, { useState, useEffect, useContext } from 'react';
import ProductContext from './ProductContext';
import { useStaticQuery, graphql } from 'gatsby';
import { crossCheckArray } from '../../helpers/ContentHelpers';

export const ProductProvider = (props) => {
  const [options, setOptions] = useState({});
  const [productId, setProductId] = useState(null);

  const data = useStaticQuery(graphql`
    {
      formlightEntities {
        v
        p
        t
      }
    }
  `);

  const { formlightEntities } = data;

  useEffect(() => {
    if (productId !== null) createOptions(productId);
  }, [productId]);

  const createOptions = () => {
    const { v, p, t } = formlightEntities;

    const {
      colors: dataColors,
      lens: dataLenses,
      guard: dataGuards,
      fixture: dataFixtures,
      lamp_type: dataLampTypes,
      mounting: dataMounting,
      simple_iten: dataSkus,
    } = v;

    let keysLenses = [],
      keysLampTypes = [],
      keysMounting = [],
      keysGuards = new Set(),
      keysWireGuards = new Set(),
      keysGuardsParents = new Set();

    let optionsFixtures = {},
      optionsColors = {},
      optionsLenses = {},
      optionsLensCh = {},
      optionsGuards = {},
      optionsGuardsCh = {},
      optionsLampTypes = {},
      optionsLampTypesCh = {},
      optionsMounting = {},
      optionsMountingCh = {},
      optionsSkus = {};


    const nodePbItems = Object.keys(v.fixture).filter(f => v.fixture[f].parent_node == productId)

    nodePbItems.map((itemId, k) => {

      if (typeof dataFixtures[itemId] !== 'undefined') {
        optionsFixtures[itemId] = dataFixtures[itemId];

        if (dataFixtures[itemId].paint_compatible === 2) {
          optionsColors = dataColors;
        }

        if (dataFixtures[itemId].lens.length > 0) {
          dataFixtures[itemId].lens.map((item, k) => {
            keysLenses.push(item);
          });
        }

        if (dataFixtures[itemId].lamp_types.length > 0) {
          dataFixtures[itemId].lamp_types.map((item, k) => {
            keysLampTypes.push(item);
          });
        }

        if (dataFixtures[itemId].mounting_position.length > 0) {
          dataFixtures[itemId].mounting_position.map((item, k) => {
            keysMounting.push(item);
          });
        }

        if (dataFixtures[itemId].guard.length > 0)
          dataFixtures[itemId].guard.map((item) => {
            keysWireGuards.add(item);
            keysGuardsParents.add(dataGuards[item].parent_node);
          });
      }

      if (typeof dataSkus[itemId] !== 'undefined') {
        optionsSkus[itemId] = dataSkus[itemId];
      }
    });

    keysLenses = new Set(keysLenses);
    keysLenses = Array.from(keysLenses);
    keysLenses = keysLenses.sort((a, b) => a - b);

    keysLampTypes = new Set(keysLampTypes);
    keysLampTypes = Array.from(keysLampTypes);
    keysLampTypes = keysLampTypes.sort((a, b) => a - b);

    keysMounting = new Set(keysMounting);
    keysMounting = Array.from(keysMounting);
    keysMounting = keysMounting.sort((a, b) => a - b);

    if (keysLenses.length > 0) {
      keysLenses.map((item, k) => {
        if (typeof p[item] !== 'undefined') {
          optionsLenses[item] = p[item];

          p[item].product_builder.map((item, k) => {
            optionsLensCh[item] = dataLenses[item];
            if (dataLenses[item].guard.length)
              dataLenses[item].guard.map((item) => {
                keysGuards.add(item);
                keysGuardsParents.add(dataGuards[item].parent_node);
              });
          });
        }
      });
    }

    if (keysLampTypes.length > 0) {
      keysLampTypes.map((item, k) => {
        if (typeof p[item] !== 'undefined') {
          optionsLampTypes[item] = p[item];

          p[item].product_builder.map((item, k) => {
            if (typeof dataLampTypes[item] !== 'undefined')
              optionsLampTypesCh[item] = dataLampTypes[item];
          });
        }
      });
    }

    if (keysMounting.length > 0) {
      Object.keys(dataMounting)
        .filter((f) =>
          crossCheckArray(dataMounting[f].mounting_position, keysMounting)
        )
        .map((item, k) => {
          optionsMountingCh[item] = dataMounting[item];
        });

      let parentsMounting = [];
      Object.keys(optionsMountingCh).map((item, k) => {
        parentsMounting.push(optionsMountingCh[item].parent_node);
      });
      parentsMounting = [...new Set(parentsMounting)];
      parentsMounting.map((item, k) => {
        if (typeof p[item] !== 'undefined') optionsMounting[item] = p[item];
      });
    }

    if (keysGuards.size) {
      keysGuards = [...keysGuards];
      keysGuards.map((item, k) => {
        if (typeof dataGuards[item] !== 'undefined')
          optionsGuardsCh[item] = dataGuards[item];
      });
    }
    if (keysWireGuards.size) {
      keysWireGuards = [...keysWireGuards];
      keysWireGuards.map((item, k) => {
        if (typeof dataGuards[item] !== 'undefined')
          optionsGuardsCh[item] = dataGuards[item];
      });
    }
    if (keysGuardsParents.size) {
      keysGuardsParents = [...keysGuardsParents];
      keysGuardsParents.map((item, k) => {
        if (typeof p[item] !== 'undefined') optionsGuards[item] = p[item];
      });
    }

    let mapMagicTabs = {
      fixture: {
        values: optionsFixtures,
        title: 'Fixtures',
        specTitle: 'Fixture',
        size: 'sm',
      },
      color: {
        values: optionsColors,
        title: 'Finishes',
        specTitle: 'Finish',
        size: 'sm',
        // modal: true,
      },
      lens: {
        values: optionsLenses,
        children: optionsLensCh,
        title: 'Lenses',
        specTitle: 'Globe',
        size: 'sm',
      },
      guard: {
        values: optionsGuards,
        children: optionsGuardsCh,
        title: 'Guards',
        specTitle: 'Guard',
        size: 'lg',
      },
      lampType: {
        values: optionsLampTypes,
        children: optionsLampTypesCh,
        title: 'Lamp types',
        specTitle: 'Lamp Type',
        size: 'modal',
      },
      mounting: {
        values: optionsMounting,
        children: optionsMountingCh,
        title: 'Mounting',
        specTitle: 'Mounting',
        size: 'modal',
      },
    };

    setOptions(mapMagicTabs);
  };

  return (
    <ProductContext.Provider
      value={{
        options: options,
        productId: productId,
        setProductId: setProductId,
      }}
    >
      {props.children}
    </ProductContext.Provider>
  );
};

export default ProductProvider;

import React from 'react'
import { excludeNodeEnv } from './DomHelper'

export const facebookPixel = async () => {

  if(excludeNodeEnv() === true) {

    const appendScript = async () => {
      let script = document.createElement("script");

      let innerHtml = ` !function(f,b,e,v,n,t,s)
                        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                        n.queue=[];t=b.createElement(e);t.async=!0;
                        t.src=v;s=b.getElementsByTagName(e)[0];
                        s.parentNode.insertBefore(t,s)}(window,document,'script',
                        'https://connect.facebook.net/en_US/fbevents.js');
                         fbq('init', '225267912336825');
                        fbq('track', 'PageView');`

      script.innerHTML = innerHtml

      await document.head.appendChild(script);
    }

    await appendScript()

  }

}

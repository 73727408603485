import {allLoadedAdjustments} from './allLoadedAdjustments';
import loadTextures from './loadTextures';
import removeTextures from './removeTextures';
import loadDefaultMatCol from './loadDefaultMatCol';
import {MeshBasicMaterial, BoxGeometry, Mesh, Box3} from 'three';
import * as THREE from 'three';

var convert = 1000 / 25.4;
var allLoaded = false;
var NUMBER_LOADED = 0;
var LOADED_NEEDED = 100;
var ROTATION_SET = false;
var shrinkAdjustment;
var adjustmentsLoaded = false;

// Sets submodel positions and rotations
// Triggeres allLoadedAdjustments function after all sub models have been processed
function load3DAssets(
  subModel,
  children,
  anchor,
  last,
  shrinking,
  scene,
  measurementContainer,
  controls,
  camera,
  canvasContainer,
  setFunctionsAllLoaded,
  functionsAllLoaded,
  passedJSON,
  setSubModelLoaded
) {
  var mesh;
  mesh = subModel.current;
  var container = new THREE.Group();
  var nextShrink;

  if (mesh){
    if (mesh.type == 'Object3D' || mesh.type == 'Mesh' || mesh.type == 'Group') {
      if (children.shrunk != 100) {
        var shrunk = children.shrunk / 100;
        mesh.add(container);
        // shrinkObject(mesh, container, shrunk);
        nextShrink = shrunk;
        var sbox = new Box3().setFromObject(mesh);
        var shrinkAmt =
          Math.abs(sbox.max.y - sbox.min.y) * ((1 - shrunk) / shrunk);
  
        shrinkAdjustment += (shrinkAmt * convert) / 10;
        

        if (passedJSON.children) {
          let subModelAmount = 0;
          for (var name in passedJSON.children) {
            subModelAmount += 1;
          }
          LOADED_NEEDED = subModelAmount;
        }

        // ADD clipping code
        /*
        var bX = Math.abs(sbox.max.x - sbox.min.x);
        var bZ = Math.abs(sbox.max.z - sbox.min.z);
  
        var clipGeometry = new BoxGeometry(0.05, 0.05, 0.025);
        var clipMaterial = new MeshBasicMaterial({
          color: 0x00ff00,
        });
  
        var clipBox = new Mesh(clipGeometry, clipMaterial);
        clipBox.name = 'clipping';
  
        var clipBoxAdj = (Math.abs(sbox.max.y - sbox.min.y) * convert) / 1000;
  
        clipBox.position.set(0, 0, clipBoxAdj);
        anchor.current.add(clipBox);
        */
        // END clipping code
      } else {
        nextShrink = 0;
      }
      // Model's position overset
      if (shrinking > 0) {
        mesh.position.set(
          children.x / convert,
          children.z / convert,
          (children.y / convert) * shrinking
        );
      } else {
        mesh.position.set(
          children.x / convert,
          children.z / convert,
          children.y / convert
        );
      }
  
      mesh.rotation.set(0, 0, 0);
  
      var rotateX = mesh.rotation.x;
      var rotateY = mesh.rotation.y;
      var rotateZ = mesh.rotation.z;
      if (children.rx != 0) {
        rotateX += 90 - children.rx;
      }
      if (children.ry != 0) {
        rotateY += 90 - children.ry;
      }
      if (children.rz != 0) {
        rotateZ += 90 - children.rz;
      }

      const allPartsLoadedCheck = () => {

        if (!functionsAllLoaded){
          NUMBER_LOADED += 1;
        }else if (functionsAllLoaded){
          NUMBER_LOADED = 0
        }

        setSubModelLoaded(true); 
        if (NUMBER_LOADED == LOADED_NEEDED && ROTATION_SET) {
          allLoaded = true;

          let adjust= true

          // Check if there are already measurements loaded
          for (let i =  0; i < anchor.current.children.length; i++){
            if (anchor.current.children[i].name === 'widthTextPoint'){
              adjust = false
              return
            }
          }

          NUMBER_LOADED = 0
          LOADED_NEEDED = 100

          if (adjust){
            allLoadedAdjustments(
              anchor,
              scene,
              measurementContainer,
              controls,
              camera,
              canvasContainer,
              setFunctionsAllLoaded
            ); 
          }
        }
      }

      const rotationUpdate = () => {
        mesh.rotation.set(
          THREE.Math.degToRad(rotateX),
          THREE.Math.degToRad(rotateZ),
          THREE.Math.degToRad(rotateY)
        );
        ROTATION_SET = true
        
        loadDefaultMatCol(subModel)
        loadTextures(subModel, children);
        allPartsLoadedCheck()  
      }

      rotationUpdate()
      
    }     
  }
}

export default load3DAssets;

import {
  MeshStandardMaterial,
  MeshNormalMaterial,
  Box3,
  BoxHelper,
  Object3D,
  Vector3,
} from 'three';

var box, bbox;
var width, height, depth;
var widthText, heightText, depthText;

// finds all text divs
var texts;
var widthTextPoint, heightTextPoint, depthTextPoint;
var convert = 1000 / 25.4;
var modes = {
  boundingBox: true,
  drafting: false,
};
var cameraH;
var shrinkAdjustment;

export function allLoadedAdjustments(
  anchor,
  scene,
  measurementContainer,
  controls,
  camera,
  canvasContainer,
  setFunctionsAllLoaded
) {
  if (modes.drafting == false) {
    updateToStandardMaterials(anchor);
  } else {
    updateToNormalMaterials(anchor);
  }

  if (typeof document !== 'undefined') {
    texts = document.getElementsByClassName('measurementDisplay');
  }

  // console.log('AllloadedAdjustments')

  updateObjects(
    anchor,
    scene,
    measurementContainer,
    controls,
    camera,
    canvasContainer,
    setFunctionsAllLoaded
  );

  for (var i = 0; i < texts.length; i++) {
    texts[i].style.position = 'absolute';
    texts[i].style.zIndex = 25;
    texts[i].style.backgroundColor = 'rgba(255,255,255,0.3)';
    texts[i].style.color = 'black';
    texts[i].style.fontFamily = 'arial';
    texts[i].style.fontWeight = 'normal';
    texts[i].style.fontStyle = 'italic';
    texts[i].style.fontSize = '15px';
  }
}

function updateToStandardMaterials(anchor) {
  for (var j = 0; j < anchor.current.children.length; j++) {
    var objects = anchor.current.children[j];
    if (objects.name != 'clipping') {
      objects.traverse((node) => {
        if (node.isMesh) {
          if (window[node.uuid] != null) {
            node.material = window[node.uuid];
          } else {
            // node.material = new MeshStandardMaterial({color: 0xffffff});
            node.material.color.setHex(0x1c1c1c);
          }
          node.material.needsUpdate = true;
        }
      });
    }
  }
}

function updateToNormalMaterials(anchor) {
  if (anchor.current.children[0].children != null) {
    var objects = anchor.current.children[0].children;
    if (objects.name != 'clipping') {
      for (var i = 0; i < objects.length; i++) {
        objects[i].traverse((node) => {
          if (node.isMesh) {
            //node.material = new MeshNormalMaterial();
            node.material.needsUpdate = true;
          }
        });
      }
    }
  }
}

export function updateObjects(
  anchor,
  scene,
  measurementContainer,
  controls,
  camera,
  canvasContainer,
  setFunctionsAllLoaded
) {
  createBoundingBox(anchor, scene);
  measureBoundingBox(
    anchor,
    measurementContainer,
    controls,
    camera,
    canvasContainer,
    setFunctionsAllLoaded
  );
  box.update();
}

export function createBoundingBox(anchor, scene) {
  bbox = new Box3().setFromObject(anchor.current);
  box = new BoxHelper(anchor.current, 0xcccccc);
  box.name = 'box';
  scene.add(box);
}

export function boundingBoxOff(scene) {
  if (typeof document !== 'undefined') {
    if (document.getElementById('width-text')) {
      scene.remove(box);
      document.getElementById('width-text').style.display = 'none';
      document.getElementById('height-text').style.display = 'none';
      document.getElementById('depth-text').style.display = 'none';
    }
  }
}

export function boundingBoxOn(scene) {
  if (typeof document !== 'undefined') {
    if (document.getElementById('width-text')) {
      scene.add(box);
      document.getElementById('width-text').style.display = 'block';
      document.getElementById('height-text').style.display = 'block';
      document.getElementById('depth-text').style.display = 'block';
    }
  }
}

export function boundingBoxUpdate() {
  if (box) {
    box.update();
  }
}

export function measureBoundingBox(
  anchor,
  measurementContainer,
  controls,
  camera,
  canvasContainer,
  setFunctionsAllLoaded
) {
  if (bbox) {
    var widthJs = Math.abs(bbox.max.x - bbox.min.x);
    var heightJs = Math.abs(bbox.max.y - bbox.min.y);
    var depthJs = Math.abs(bbox.max.z - bbox.min.z);

    widthTextPoint = new Object3D();
    widthTextPoint.name = 'widthTextPoint';
    heightTextPoint = new Object3D();
    heightTextPoint.name = 'heightTextPoint';
    depthTextPoint = new Object3D();
    depthTextPoint.name = 'depthTextPoint';

    // Move controls target to middle of object
    controls.current.target.set(bbox.min.x, bbox.min.y, bbox.min.z);
    controls.current.target.x += widthJs / 2;
    controls.current.target.y += heightJs / 2;
    controls.current.target.z += depthJs / 2;

    // Move comer x and y positions to middel of object
    camera.position.set(0, 0, camera.position.z);
    camera.position.set(bbox.min.x, bbox.min.y, camera.position.z);
    camera.position.x += widthJs / 2;
    camera.position.y = heightJs / 2;

    anchor.current.add(widthTextPoint);
    anchor.current.add(heightTextPoint);
    anchor.current.add(depthTextPoint);

    displayMeasurement(
      measurementContainer,
      canvasContainer,
      setFunctionsAllLoaded
    );
    cameraH = 10 + height / 15;
  }
}

export function updateTextPoints(anchor) {
  if (box) {
    bbox = new Box3().setFromObject(anchor.current);
  }
  box.update();
  var widthJs = Math.abs(bbox.max.x - bbox.min.x);
  var heightJs = Math.abs(bbox.max.y - bbox.min.y);
  var depthJs = Math.abs(bbox.max.z - bbox.min.z);

  width = ((widthJs * convert) / 10).toFixed(2);
  height = ((heightJs * convert) / 10).toFixed(2);
  depth = ((depthJs * convert) / 10).toFixed(2);

  var wpos = bbox.min.x + (bbox.max.x - bbox.min.x) / 2;
  var hpos = bbox.min.y + (bbox.max.y - bbox.min.y) / 2;
  var dpos = bbox.min.z + (bbox.max.z - bbox.min.z) / 2;

  var wOffset = (bbox.max.x - bbox.min.x) / 2;
  var hOffset = (bbox.max.y - bbox.min.y) / 2;
  var dOffset = (bbox.max.z - bbox.min.z) / 2;

  var xOffset = bbox.min.x - widthJs / 2;
  var yOffset = bbox.min.y - heightJs / 2;

  var wtPosition = new Vector3(bbox.min.x, bbox.max.y - hOffset, bbox.max.z);
  var htPosition = new Vector3(
    bbox.min.x - wOffset,
    hpos - hOffset,
    bbox.max.z
  );
  var dtPosition = new Vector3(
    bbox.max.x - wOffset,
    bbox.max.y - hOffset,
    dpos
  );

  var anchorOffset = new Vector3(-widthJs / 2, -heightJs / 2, 0);

  wtPosition.sub(anchorOffset);
  htPosition.sub(anchorOffset);
  dtPosition.sub(anchorOffset);

  wtPosition.divideScalar(10);
  htPosition.divideScalar(10);
  dtPosition.divideScalar(10);

  var axis = new Vector3(1, 0, 0);
  var angle = Math.PI / 2;
  wtPosition.applyAxisAngle(axis, angle);
  htPosition.applyAxisAngle(axis, angle);
  dtPosition.applyAxisAngle(axis, angle);

  widthTextPoint.position.copy(wtPosition);
  heightTextPoint.position.copy(htPosition);
  depthTextPoint.position.copy(dtPosition);

  updateDisplayMeasurement(width, height, depth);
  cameraH = 10 + height / 15;
}

function updateDisplayMeasurement(width, height, depth) {
  if (shrinkAdjustment > 0) {
    height = parseFloat(height) + shrinkAdjustment;
  }

  var wFrac = (Math.round(width * 4) / 4).toFixed(2);
  var hFrac = (Math.round(height * 4) / 4).toFixed(2);
  var dFrac = (Math.round(depth * 4) / 4).toFixed(2);

  if (typeof document !== 'undefined') {
    if (document.getElementById('width-text')) {
      document.getElementById('width-text').innerHTML = String('w:' + wFrac);
      document.getElementById('height-text').innerHTML = String('h:' + hFrac);
      document.getElementById('depth-text').innerHTML = String('d:' + dFrac);
    }
  }
}

function displayMeasurement(
  measurementContainer,
  canvasContainer,
  setFunctionsAllLoaded
) {
  if (shrinkAdjustment > 0) {
    height = parseFloat(height) + shrinkAdjustment;
  }

  var wFrac = (Math.round(width * 4) / 4).toFixed(2);
  var hFrac = (Math.round(height * 4) / 4).toFixed(2);
  var dFrac = (Math.round(depth * 4) / 4).toFixed(2);

  if (typeof document !== 'undefined') {
    widthText = document.createElement('div');
  }
  widthText.className = 'measurementDisplay';
  widthText.id = 'width-text';
  widthText.innerHTML = String('w:' + wFrac);
  measurementContainer.current.appendChild(widthText);
  if (typeof document !== 'undefined') {
    heightText = document.createElement('div');
  }
  heightText.className = 'measurementDisplay';
  heightText.id = 'height-text';

  if (shrinkAdjustment > 0) {
    heightText.innerHTML = String('h:' + hFrac + ' [clipped]');
  } else {
    heightText.innerHTML = String('h:' + hFrac);
  }

  measurementContainer.current.appendChild(heightText);
  if (typeof document !== 'undefined') {
    depthText = document.createElement('div');
  }
  depthText.className = 'measurementDisplay';
  depthText.id = 'depth-text';
  depthText.innerHTML = String('d:' + dFrac);
  measurementContainer.current.appendChild(depthText);

  setFunctionsAllLoaded(true);
}

// Update floating measurement text positions
export function updateMeasurementPositions(viewWidth, viewHeight, camera) {
  if (widthTextPoint != null) {
    var wscreen = toScreenPosition(
      widthTextPoint,
      viewWidth,
      viewHeight,
      camera
    );
    widthText.style.left = String(wscreen.x) + 'px';
    widthText.style.top = String(wscreen.y) + 'px';

    var hscreen = toScreenPosition(
      heightTextPoint,
      viewWidth,
      viewHeight,
      camera
    );
    heightText.style.left = String(hscreen.x) + 'px';
    heightText.style.top = String(hscreen.y) + 'px';

    var dscreen = toScreenPosition(
      depthTextPoint,
      viewWidth,
      viewHeight,
      camera
    );
    depthText.style.left = String(dscreen.x) + 'px';
    depthText.style.top = String(dscreen.y) + 'px';
  }
}

function toScreenPosition(obj, viewWidth, viewHeight, camera) {
  var vector = new Vector3();
  var widthHalf = 0.5 * viewWidth;
  var heightHalf = 0.5 * viewHeight;
  obj.updateMatrixWorld();
  vector.setFromMatrixPosition(obj.matrixWorld);
  vector.project(camera);
  vector.x = vector.x * widthHalf + widthHalf;
  vector.y = -(vector.y * heightHalf) + heightHalf;
  return {
    x: vector.x,
    y: vector.y,
  };
}

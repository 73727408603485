import React, { useState, useEffect } from 'react';
import GatsbyLink from 'gatsby-link';
import { navigate } from "gatsby"
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ReadMore from './ReadMore';
import LoaderCenterHorizontal from './LoaderCenterHorizontal';
import { jsonToReconfig } from '../../helpers/ContentHelpers';
import { getBuild } from '../../helpers/DrupalHelper';
import {
  // attachThreeJs,
  // detatchThreeJs,
  cleanDrupalMarkup,
  excludeNodeEnv
} from '../../helpers/DomHelper';
import SocialShare from './SocialShare'
import ProductMeta from '../Product/ProductMeta'
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ReactHtmlParser from 'react-html-parser'
import ThreeJs from '../ThreeJs/index.js'
import { useStaticQuery, graphql } from "gatsby"


function Modal3d(props) {

  const {
    state
  } = props

  const {
    modal: modal,
    modalContext: entity,
    modalSiblings: siblings,
    modalIndex: index,
    addToCart: addToCart,
    loading: loading,
    updateLoading: updateLoading,
    token: token
  } = state;

  const data = useStaticQuery(graphql`
    query {
      allFormlightProduct {
        edges {
          node {
            id
            nodeId
            nodeName
            pageUrl
            nodeBody
          }
        }
      }
    }
  `)

  let total = 1
  if(siblings !== null && siblings.length) total = siblings.length

  let prevProduct, nextProduct

  if(total > 1) {
    prevProduct = index !== 0 ? siblings[index - 1] : siblings[total - 1]
    nextProduct = index !== siblings.length - 1 ? siblings[index + 1] : siblings[0]
  }

  // let initCartCount = parseInt(1);

  const [product, setProduct] = useState(null);
  const [productCartCount, setProductCartCount] = useState(1);

  const getProduct = async (handle) => {

    await getBuild(
      token,
      handle
    ).then(data => {
      setProduct(data)
      updateLoading(false)
    })
    // .then(() => {
    //   attachThreeJs()
    // })
    .catch(e => {
      console.log("error")
      console.log(e)
    })

  }

  const handleClose = () => {
    // detatchThreeJs()
    props.updateModal(false)
  }

  const gotoProduct = (pageLink) => {
    handleClose()
    navigate(pageLink)
  }

  const increaseProductAmount = () => {
    let newQuantity = productCartCount + 1;
    setProductCartCount(newQuantity);
  }

  const decreaseProductAmount = () => {
    if (productCartCount === 1) return;
    let newQuantity = productCartCount - 1;
    setProductCartCount(newQuantity);
  }

  const updateContent = (productObject, direction = 'next') => {
    // detatchThreeJs()

    let newProduct = productObject,
        oldIndex = index,
        newIndex = oldIndex !== total -1 ? oldIndex + 1 : 0;

    if(direction === 'prev') {
      newIndex = oldIndex !== 0 ? oldIndex - 1 : total;
    }

    props.updateModal(newProduct, 'three', newIndex, siblings, entity)

    updateLoading(true)
    setProductCartCount(1);
  }

  const reconfigureBuild = (link) => {
    handleClose()
    navigate(link)
  }

  useEffect(() => {
    getProduct(modal.title)
  }, [modal.title]);

  if(product !== null){

    let json = JSON.parse(product.json),
        node = json["n"],
        coreProduct,
        coreProductT,
        coreProductC,
        coreProductD,
        productNode = {}

    if(entity){
      coreProduct = entity.p[node]
      coreProductT = coreProduct._t
      coreProductC = coreProduct._c
      coreProductD = coreProduct._d

      data.allFormlightProduct.edges.filter(item => item.node.nodeId === node).map(item => productNode = item.node)
      // console.log(productNode)
      // console.log("productNode")

    }

    let reConfigBase = jsonToReconfig(product.json)
        reConfigBase = `/create?b=${reConfigBase}`

    return (

      <Dialog
        fullScreen
        open={modal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="ccnModalInsp"
      >
      <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close" style={{width: '50px', margin: '10px auto', position: 'absolute', top: '0', right: '10px', zIndex: '1'}}>
        <CloseIcon />
      </IconButton>
      {(() => {
        if(loading === true) {
          return (
            <LoaderCenterHorizontal />
          )
        } else {
          return (
            <DialogContent>
              <div class="inspocontent inspocontent-modal">
                <div class="threeViewer threeBackdrop">
                  {excludeNodeEnv() === true &&
                    <ThreeJs model3d={product.three} />
                  }
                  {/*<div id={'scene3d'} style={{height: '100vh', width: '100%'}}>
                    <div id={'gui-container'}></div>
                    <div id={'measurement-container'}></div>
                  </div>
                  <div id={'threejsjson'} style={{display: 'none'}}>
                    {product.three}
                  </div>*/}
                </div>
                <div class="detailViewer">
                  <div class="row align-items-center ccnInspoNav">
                    {/*<div class="col-xl-8 col-12">
                      <div class="breadcrumb-area">
                        <ul class="page-breadcrumb-three">
                          <li><GatsbyLink to="/">Home</GatsbyLink></li>
                          <li><a onClick={handleClose}>Inspiration</a></li>
                        </ul>
                      </div>
                    </div>*/}
                    {total > 1 &&
                      <div class="col-xl-4 col-12">
                        <div class="portfolio-nav-area">
                          <ul>
                            <li><a onClick={v => { updateContent(prevProduct, 'prev') }}><i class="fa fa-angle-left"></i></a></li>
                            <li><a onClick={handleClose}><i class="dlicon ui-2_grid-45"></i></a></li>
                            <li><a onClick={v => { updateContent(nextProduct, 'next') }}><i class="fa fa-angle-right"></i></a></li>
                          </ul>
                        </div>
                      </div>
                    }
                  </div>
                  <div class="product-details-content quickview-content">
                    {/*<h3 class="mb-10">Custom Build</h3>*/}
                    <h2 className="mt-30">{product.part_number}</h2>
                    <p class="product-price product-details-price"> <span class="discounted-price">{product.v}</span></p>
                    <div class="pro-desc">
                      {/*<p>Order this build as is, or reconfigure it for your space.</p>*/}
                      {/* {Object.keys(productNode).length > 0 &&
                        <>
                          <p>Using <a onClick={(v) => gotoProduct(productNode.pageUrl) }>{coreProductT}</a></p>
                          <ReadMore color="#fff"  title="Read more">{ReactHtmlParser(cleanDrupalMarkup(productNode.nodeBody))}</ReadMore>
                        </>
                      } */}
                    </div>
                    <div class="pro-details-quality pro-details-btns-attchd">
                      <div class="quantity quantity--2">
                        <input type="text" class="quantity-input" name="qty" id="qty-4" value={productCartCount}/>
                        <div class="dec qtybutton" onClick={decreaseProductAmount}>-</div>
                        <div class="inc qtybutton" onClick={increaseProductAmount}>+</div>
                      </div>
                      <a className="bk-btn bk-btn-cart ml-3" onClick={(v) => { addToCart({handle: product.title, quantity: productCartCount}) }}>Add To Cart</a>
                      {/*<span className="bk-btn-sep d-none d-lg-flex ml-3 mr-3"></span>*/}
                      <a className="bk-btn bk-btn-reconfigure ml-3" onClick={(v) => reconfigureBuild(reConfigBase)}>Reconfigure</a>
                    </div>
                    <ProductMeta />
                    <SocialShare
                      url={product.alias}
                      title={'Custom Build ' + product.part_number}
                    />
                  </div>
                </div>
              </div>
            </DialogContent>
          )
        }
      })()}
    </Dialog>
  )}
  return null
}

export default Modal3d;

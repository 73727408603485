class JSONRebuilder {
  constructor(JSON) {
    // console.log('RAW JSON', JSON);
    this.sourceJSON = JSON;
    this.newJSON = {};
    this.models = {};
    this.fetchModels();
    this.materials = {};
    this.fetchMaterials();
    this.dimensions = {};
    this.fetchDimensions();
    this.tempCounter = {};
    this.children = {};
  
    if (this.sourceJSON.children.length){
       this.sourceJSON.children.forEach((child) => { 
        this.fetchChild(child);
      });
    }

    this.newJSON = {
      children: this.children,
      models: this.models,
      materials: this.materials,
      dimensions: this.dimensions,
    };
  }

  fetchModels() {
    Object.keys(this.sourceJSON.model).forEach((key) => {
      this.models[key] = {};
      this.models[key].url = this.sourceJSON.model[key].url;
    });
  }

  fetchMaterials() {
    Object.keys(this.sourceJSON.finish).forEach((key) => {
      this.materials[key] = {};
      this.materials[key].url = this.sourceJSON.finish[key].url;
      this.materials[key].normalUrl = this.sourceJSON.finish[key].normalUrl;
    });
  }

  fetchDimensions() {
    this.dimensions = this.sourceJSON.settings.dimensions;
  }

  fetchChild(child, parent = null) {
    if (this.tempCounter[child.type] !== undefined) {
      this.tempCounter[child.type] += 1;
    } else {
      this.tempCounter[child.type] = 0;
    }
    // Places under score after type and addes number, to make another clone of same object later
    const id = child.type + '_' + this.tempCounter[child.type];
    child.type = id;
    const material = {...child.finish};
    this.children[id] = {
      type: child.type,
      parent: parent ? parent : null,
      x:
        parent && this.children[parent].x
          ? child.x + this.children[parent].x
          : child.x,
      y:
        parent && this.children[parent].y
          ? child.y + this.children[parent].y
          : child.y,
      z:
        parent && this.children[parent].z
          ? child.z + this.children[parent].z
          : child.z,
      rx:
        /*
        parent && this.children[parent].rx
         ? child.rx + this.children[parent].rx
          : */ child.rx,
      ry:
        /* 
        parent && this.children[parent].ry
       ? child.ry + this.children[parent].ry
          : */ child.ry,
      rz:
        /* 
        parent && this.children[parent].rz
         ? child.rz + this.children[parent].rz
          : */ child.rz,
      material,
    };
    if (child.children) {
      child.children.forEach((a) => {
        this.fetchChild(a, id);
      });
    }
  }

  getJSON() {
    // console.log('REBUILT JSON', this.newJSON);
    return this.newJSON;
  }
}

export default JSONRebuilder;

import React from 'react'
import styled, { css } from 'styled-components'

const QtyContainer = styled('div')`
  &&& {
    color: #1a1a1a;
    position: relative;
    font-size: 20px;
    font-weight: 500;
    /* width: 120px; */
    width: 80px;
    height: 54px;
    line-height: 1;
    &:hover input {
      border-color: #1a1a1a;
    }
  }
`
const QtyInput = styled('input')`
  &&& {
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #fff;
    box-shadow: 0 10px 15px rgba(0, 0, 0, .02), 0 4px 10px rgba(0, 0, 0, .01);
    font-size: 14px;
    text-align: center;
    color: #1a1a1a;
    width: 100%;
    height: 100%;
  }
`
const QtyButton = styled('div')`
  &&& {
    position: absolute;
    cursor: pointer;
    top: 0;
    font-weight: 700;
    height: 54px;
    line-height: 54px;
    font-size: 14px;
    font-weight: normal;
    transition: all .3s ease-in-out;
    width: 20px;
    ${props => props.dec && css`
      left: 0;
      text-align: right;
    `}
    ${props => props.inc && css`
      right: 0;
      text-align: left;
    `}
  }
`

function ProductCounter({ currentAmount, increaseAmount, decreaseAmount }) {

  // const [qtyLoading, setQtyLoading] = useState(false)

  // const increaseQuantity = async () => {
  //   await setQtyLoading(true)
  //   await increaseAmount()
  //   setQtyLoading(false)
  // }
  //
  // const decreaseQuantity = async () => {
  //   await setQtyLoading(true)
  //   await decreaseAmount()
  //   setQtyLoading(false)
  // }

  return (
    <QtyContainer>
      <QtyInput type="text" class="" name="qty" id="qty-4" value={currentAmount}/>
      <QtyButton dec onClick={decreaseAmount}>-</QtyButton>
      <QtyButton inc onClick={increaseAmount}>+</QtyButton>
    </QtyContainer>
  );
}

export default ProductCounter;

import React from 'react';
import { Flex, Box, Text } from 'rebass';

const ProductMeta = (props) => {

  return (
    <Box className="product-html-custom" pt={[0, 0, `48px`, `28px`]}>
        <span><i class="dlicon shopping_delivery-fast"></i>Ships to US & Canada</span>
        <span><i class="dlicon ui-1_check-curve"></i>Includes warranty</span>
    </Box>
  );
};



export default ProductMeta;

import React from 'react';
import {
  EmailShareButton,
  FacebookShareButton,
  PinterestShareButton,
  TwitterShareButton
} from "react-share";


const SocialShare = (props) => {

  const {
    url,
    title,
    desc,
    image
  } = props;

  const viaUrl = '@bocklighting';
  const viaHash = [
    '#MyFormLight'
  ];

  let shareUrl = 'https://www.formlight.com',
      shareTitle = 'FormLight by Bock',
      shareQuote = 'https://www.formlight.com - FormLight by Bock',
      shareImage = ' ';

  if(url){
      shareUrl = shareUrl + url;
  }
  if(title){
      shareTitle = title + ' on ' + shareTitle;
  }

  if(url && title){
    shareQuote = 'Check out ' + title + ' on FormLight by Bock at ' + shareUrl + ' ' + viaUrl;
  }

  if(image){
    shareImage = image;
  }

  return (
    <div className="pro-details-social">
      <h4>Share this</h4>
      <ul>
        <li>
          <FacebookShareButton
            quote={shareQuote}
            url={shareUrl}
            resetButtonStyle={false}
            className="facebook"
            >
            <i class="fa fa-facebook"></i>
          </FacebookShareButton>
        </li>
        <li>
          <TwitterShareButton
            url={shareUrl}
            title={shareTitle}
            via={viaUrl}
            hashtags={viaHash}
            resetButtonStyle={false}
            className="twitter"
            >
            <i class="fa fa-twitter"></i>
          </TwitterShareButton>
        </li>
        <li>
          <PinterestShareButton
            description={shareQuote}
            media={shareImage}
            url={shareUrl}
            resetButtonStyle={false}
            className="pinterest"
            >
            <i class="fa fa-pinterest-p"></i>
          </PinterestShareButton>
        </li>
        <li>
          <EmailShareButton
            subject={shareTitle}
            body={shareQuote}
            url={shareUrl}
            resetButtonStyle={false}
            className="email"
            >
            <i class="fa fa-at"></i>
          </EmailShareButton>
        </li>
      </ul>
    </div>
  );
};



export default SocialShare;

const removeTextures = (subModel, children, matName, matAnimateIn, setMatAnimateIn) => {
  const mesh = subModel.current;
  const convert = 1000 / 25.4;

  if (mesh.type == 'Object3D' || mesh.type == 'Mesh' || mesh.type == 'Group') {
    mesh.traverse((node) => {
      if (node.isMesh) {
        if (['mat1', 'mat2', 'mat3'].some((a) => a === node.material.name)) {     
          if (node.material.map) {
            if (node.material.name === matName){
              node.material.map = null;
              node.material.normalMap = null;
              node.material.needsUpdate = true;
              node.material.color.setHex(0x1c1c1c)
              node.material.opacity = 0
            }
          }
        }
      }
    });
  }
};

export default removeTextures;

import { MeshPhongMaterial, MeshPhysicalMaterial, MeshStandardMaterial} from 'three';

const loadDefaultMatCol = (subModel,matName) => {
    const mesh = subModel.current;
    const convert = 1000 / 25.4;
  
    if (mesh.type == 'Object3D' || mesh.type == 'Mesh' || mesh.type == 'Group') {
      mesh.traverse((node) => {
        if (node.isMesh) {
          if (node.material){

            const storeMatName = node.material.name
            node.material = new MeshPhysicalMaterial( { color: 0x1c1c1c } );
            node.material.name = storeMatName
            // console.log('node.material.name', node.material.name)
            // node.material = new MeshStandardMaterial( { color: 0x1c1c1c } );
           // node.material.map = null;
           // node.material.normalMap = null;
            node.material.needsUpdate = true;
            node.material.clearcoat = 1;
            //node.material.color.setHex(0x1c1c1c);
            
            node.material.opacity = 0;
            // console.log('storeMatName', storeMatName)
            // console.log('loadDefault node.material', node.material)
          }
        }
      });
    }
  };
  
  export default loadDefaultMatCol;
  
import React, {useRef, useState, Suspense, useEffect, memo} from 'react';
import '../utils/loadTextures';
import {LoadingManager} from 'three/src/loaders/LoadingManager';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader';
import {ZipLoader} from 'three-ziploader/src/ZipLoader';
import {DRACOLoader} from 'three/examples/jsm/loaders/DRACOLoader';
import SubModel from './SubModel';

const LoadSubModel = ({  
    anchor,
    child,
    modelURL,
    childObj,
    scene,
    measurementContainer,
    texturesAllLoaded,
    passedJSON,
    controls,
    canvasContainer,
    setFunctionsAllLoaded,
    functionsAllLoaded,
    setRotated,
    rotated}) => {
  
    const [gltf, setGltf] = useState()
    const [savedLoadJson, setLoadSavedJson] = useState()  
  
    // Loader setup
    var manager = new LoadingManager();
    var loader = new GLTFLoader(manager);
    var dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath('https://scripts.source.xyz/js/draco/gltf/');
    dracoLoader.setDecoderConfig({type: 'js'});
    loader.setDRACOLoader(dracoLoader);
    
    // Log load process
    /*
    manager.onStart = function ( url, itemsLoaded, itemsTotal ) {
      console.log( 'Started loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.' ); 
    };
    
    manager.onLoad = function ( ) {
      console.log( 'Loading complete!');
    };
    
    
    manager.onProgress = function ( url, itemsLoaded, itemsTotal ) { 
      console.log( 'Loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.' ); 
    };
    
    manager.onError = function ( url ) {  
      console.log( 'There was an error loading ' + url ); 
    };
    */
  
    // Models load with helpers
    function loadModel(url) {
      new Promise(function (resolve, reject) {
        if (url.match(/\.zip$/)) {
          new ZipLoader().load(url).then(function (zip) {
            manager.setURLModifier(zip.urlResolver);
            resolve(zip.find(/\.(gltf|glb)$/i)[0]);
          });
        } else {
          resolve(url);
        }
      }).then(function (file) {
        loader.load(file, function (gltf) {
          setGltf(gltf);
          // console.log('Load Model gltf', gltf)
          setLoadSavedJson(passedJSON)
        });
      });
    }  
  
    // Check if mounted
      useEffect(()=>{
        // console.log('Mounted LoadSubModel', childObj.type)
        return () =>{
          // console.log( 'Umounted LoadSubModel', childObj.type)
        }
      },[])
  
    // Loads models if url passed 
    useEffect(() => {
      if (!savedLoadJson && modelURL) {
        loadModel(modelURL);
      }
    }, [passedJSON]);
  
    if (gltf ) {
      return (      
      <SubModel
        anchor={anchor}
        child={child}
        modelURL={modelURL}
        childObj={childObj}
        scene={scene}
        measurementContainer={measurementContainer}
        texturesAllLoaded={texturesAllLoaded}
        passedJSON={passedJSON}
        controls={controls}
        canvasContainer={canvasContainer}
        setFunctionsAllLoaded={setFunctionsAllLoaded}
        functionsAllLoaded={functionsAllLoaded}
        setRotated={setRotated}
        rotated={rotated}
        modelLoad={gltf}            
      />   
    );}else{return null}
  }
  
 // export default memo(LoadSubModel)
 export default LoadSubModel
import React, {useRef, useState, Suspense, useEffect, memo} from 'react';
import {useFrame, useThree} from 'react-three-fiber';
import {
  updateMeasurementPositions,
  boundingBoxOff,
  boundingBoxOn,
  updateTextPoints,
} from '../utils/allLoadedAdjustments';
import '../utils/loadTextures';
import '../utils/deepEqual'; 
import LoadSubModel from './LoadSubModel';
import deepEqual from '../utils/deepEqual';


// Creates model componants from mapping children in passed Json object
const MapModels = ({
    passedJSON,
    anchor,
    scene,
    measurementContainer,
    texturesAllLoaded,
    controls,
    canvasContainer,
    setFunctionsAllLoaded,
    functionsAllLoaded,
    setRotated,
    rotated,
    reload,
    setReload
    //scene3dSortArr,
    //setScene3dSortArr
    }) => {

    const [passedJsonChildren, setPassedJsonChildren] = useState()
    const [savedPassedJsonChildren, setSavedPassedJsonChildren] = useState()
   // const [savedSortArr, setSavedSortArr] = useState()
    const [currentJson, setCurrentJson] = useState()
   // const [reloadEachPage, setReloadEachPage] = useState()
   // const [equalChangeMod, setEqualChangeMode] = useState()
    const [sortArr, setSortArr] = useState()
    const [savedPassedJSON, setSavedPassedJSON] = useState()

    // Mount check
    useEffect(()=>{
      // console.log('mount MapModels')
      return () =>{
        // console.log('unmounted MapModels')       
        setPassedJsonChildren(undefined)
        setSavedPassedJsonChildren(undefined)
        setSortArr(undefined)
        setCurrentJson(undefined)
      }
    },[])
    
    
    // Save passed json data to state
    useEffect(()=>{
      if (passedJSON.children){
        setPassedJsonChildren(Object.keys(passedJSON.children))
      }
    },[passedJSON])

    // Check if smaller array's elements is invcluded in larger array
    const isInArr = (smallArr, largeArr) => {
      let result
      for (let y = 0; y < smallArr.length; y++){     
        // Insert new models
        if (!largeArr.includes(smallArr[y])){
          // console.log('Different array', smallArr)
          result = false
          y = smallArr.length
        } else {
          // console.log('Same array')
          result = true
        } 
      } 
      return result 
    }

    // Update mapped model array with changed data
    const updateArr = () => {
      let changedArr = []
      // Pick up first different element and push elements in both to chandeArr array
      for (let i = 0; i < sortArr.length; i++){
        if(!passedJsonChildren.includes(sortArr[i])){
          // console.log('First found different submodel',sortArr[i])
            // Push elements before the first found different element - TODO - Models in BOTH up to change
            for (let z = 0; z < i ; z++){
              if (passedJsonChildren.includes(sortArr[z]) && !changedArr.includes(passedJsonChildren[i])){
                changedArr.push(sortArr[z])
                // console.log('push models up to changed', sortArr[z] )
              }                               
            }
          // Stop loop
           i= sortArr.length
        }
        // Update scene to only have both up to changed
        setSortArr(changedArr);
        setPassedJsonChildren(Object.keys(passedJSON.children))
        // Wait and add the odd model elements
        // Then addd other odd elements after it if any
        setTimeout(function(){

          
          // Push all odd models 
          for (let i = 0; i < passedJsonChildren.length; i++){
            if(!sortArr.includes(passedJsonChildren[i]) && !changedArr.includes(passedJsonChildren[i]) ){
              changedArr.push(passedJsonChildren[i])
              // console.log('push different model', passedJsonChildren[i])
            }
          }

          // Update scene to only have both up to changed
          setSortArr(changedArr);
          setPassedJsonChildren(Object.keys(passedJSON.children))
         
          // Push all remaing even models
          for (let i = 0; i < passedJsonChildren.length; i++){
            if(!changedArr.includes(passedJsonChildren[i])){
              changedArr.push(passedJsonChildren[i])
            }
          }
        }, 1500);
      }
      setSortArr(changedArr);
      setSavedPassedJSON(passedJSON)
      setSavedPassedJsonChildren(passedJsonChildren)
      // console.log('changedArr', changedArr)
      setCurrentJson(passedJSON) 
    }

    // Current sort array set
    useEffect(()=>{
      if (passedJsonChildren){
        
        // console.log('passedJsonChildren', passedJsonChildren)

        switch(true){

          // Start - if nothing has been saved yet && array only has one element (for page reload) && there is not saved arrays yet -> pass json and save arr
        case !sortArr /* && passedJsonChildren.length === 1 */:
          // console.log(' MapModel - First trigger')
          setSortArr(passedJsonChildren)
          setSavedPassedJSON(passedJSON)
          setSavedPassedJsonChildren(passedJsonChildren)
          setCurrentJson(passedJSON)
          break 

        // Change More 
        case sortArr && passedJsonChildren.length > sortArr.length && !isInArr(sortArr , passedJsonChildren):
          updateArr()
          break

          
          // Change Same - to selection with array with same amount of elements to saved
        case sortArr /* && !reloadEachPage */ && passedJsonChildren.length > 1 && passedJsonChildren.length ===  savedPassedJsonChildren.length && passedJsonChildren.sort().join(',') !== savedPassedJsonChildren.sort().join(',') /* || sortArr && passedJsonChildren.length > sortArr.length && !isInArr(sortArr , passedJsonChildren ) */ : 
          updateArr()
          break
                       
          // ADD/Change to more - if additional model in passeds json, only push the new element on sortArr - TODO: All of sortArr is in passedJsoon - Not remoing all children for duplicates subModels
        case sortArr /* && !reloadEachPage */ && passedJsonChildren.length > savedPassedJsonChildren.length && isInArr(savedPassedJsonChildren , passedJsonChildren):
          // console.log('MapModel - Add trigger')
          let extendArr = sortArr

            // Change and remove excluded

            // Add new models - set timeout
            passedJsonChildren.map((child) => {
              if(sortArr.includes(child) === false){
                extendArr.push(child)
                // console.log('Add different child', child)
              }
            })
            // console.log(' extendArr', extendArr)
            // console.log('passedJsonChildren after extendArr', passedJsonChildren)
            // console.log('extendArr passedJsonChildren', passedJsonChildren)
            // console.log('extendArr savedSortArr', savedSortArr)
            setSortArr(extendArr);
            setSavedPassedJSON(passedJSON)
            setSavedPassedJsonChildren(passedJsonChildren)
            setCurrentJson(passedJSON)
            break

          // Remove / Change to Less - if there are less models in passed json, remove the missing element from sortArr && TODO: if All of sortArr is in passedJsoon 
          case sortArr /*  && !reloadEachPage */ && passedJsonChildren.length < savedPassedJsonChildren.length /* &&  isInArr(passedJsonChildren, savedPassedJsonChildren) */:
            updateArr()
            break
       

          // Reuse savewd AR if no models have changed for textures to update
          default:
            // console.log('Default for texture')
            if ( isInArr(passedJsonChildren,savedPassedJsonChildren) ){
              // console.log('MapModels - Default change texture')
              setSortArr(sortArr)
              setSavedPassedJSON(passedJSON)
              setSavedPassedJsonChildren(passedJsonChildren) 
              setCurrentJson(passedJSON)
            }
            break 
        }
      }    
    },[passedJsonChildren])
    

    // Saved sort array for MapModel and scene3d
    useEffect(()=>{
      if (sortArr){
        // console.log('SORTED', sortArr)
        // setSavedSortArr(sortArr)
        // setSavedPassedJsonChildren(passedJsonChildren)
        // setScene3dSortArr(sortArr)
        // setReload(true)
      }
    },[sortArr])

    // Check savedPassedJsonChildren when saved
    useEffect(()=>{
       // console.log('savedPassedJsonChildren when saved', savedPassedJsonChildren)
    },[savedPassedJsonChildren])
    
    // console.log( 'sortArr', sortArr )
    
    if (sortArr   && currentJson  &&  !reload){
      // console.log('currentJson Map', currentJson)
      return ( 
        <>
          {sortArr.map((child) => {
            
            const childObj = currentJson.children[child];
            // console.log('childObj mapping', childObj)
            // console.log('currentJson mapping', currentJson)
            // console.log('child', child)
            // console.log('sortArr', sortArr)
            const modelURL =
              currentJson.models[
                childObj.type.substring(0, childObj.type.length - 2)
              ].url;
            
              return (         
                <LoadSubModel
                  anchor={anchor}
                  child={child}
                  modelURL={modelURL}
                  childObj={childObj}
                  scene={scene}
                  measurementContainer={measurementContainer}
                  texturesAllLoaded={texturesAllLoaded}
                  passedJSON={currentJson}
                  controls={controls}
                  canvasContainer={canvasContainer}
                  setFunctionsAllLoaded={setFunctionsAllLoaded}
                  functionsAllLoaded={functionsAllLoaded}
                  setRotated={setRotated}
                  rotated={rotated} 
                />   
              );  
          })}
        </> 
      )
    }else {
      return null;
    }
 
}

// Returns SubModel components depending on amount of children models listed in JSON
// Passes Json data into each spawned SubModel component
const Model = ({
  passedJSON,
  anchor,
  measurementContainer,
  scene3dContainer,
  texturesAllLoaded,
  controls,
  setFunctionsAllLoaded,
  functionsAllLoaded,
  loadHdriComplete,
  reload,
  setReload
  //scene3dSortArr,
  //setScene3dSortArr
}) => {
  let viewWidth;
  let viewHeight;
  const {scene, camera, gl} = useThree();
  const [bboxPresent, setBboxPresent] = useState(false);
  const [rotated, setRotated] = useState();
  let canvasContainer;
  if (typeof document !== 'undefined') {
    canvasContainer = document.getElementById('canvas');
  }


  // Check if model component is unmounted or mounted
  useEffect(()=>{
    // console.log("Model Mounted")
    return () =>{
      // console.log("Model Unmounted")
    }
  },[])
  
  // Anchor, rotated submodel state and bounding box visibility defined
  useEffect(() => {
    if (passedJSON){
      // console.log('passedJSON', passedJSON)
    }
    // Setup 
    setRotated(false);
    anchor.current.scale.set(10, 10, 10);
    anchor.current.rotation.set(-Math.PI / 2, 0, 0);
    anchor.current.name = 'anchor';
    if (passedJSON.dimensions === 1) {
      setBboxPresent(true);
    } else if (passedJSON.dimensions === 0) {
      setBboxPresent(false);
    }
  }, [passedJSON]);

  // Only displays 3d once the adjustments functions have been processed as well
  useEffect(() => {
    if (!functionsAllLoaded && !loadHdriComplete) {
      measurementContainer.current.style.opacity = '0%';
      canvasContainer.style.opacity = '0%';
    } else if (functionsAllLoaded && loadHdriComplete ) {
      measurementContainer.current.style.opacity = '100%';
      canvasContainer.style.opacity = '100%';
    }

  }, [functionsAllLoaded]);

  // Measurement text postiions update and bounding box visibility
  useFrame(() => {
    if (scene3dContainer.current){
      viewWidth = scene3dContainer.current.offsetWidth;
      viewHeight = scene3dContainer.current.offsetHeight;

    }
    if (scene.getObjectByName('box')) {
      updateTextPoints(anchor);
    }
    updateMeasurementPositions(viewWidth, viewHeight, camera);
  });

  // Turn bounding box and measurements text on or off
  useEffect(() => {
    if (!bboxPresent) {
      boundingBoxOff(scene);
    } else if (bboxPresent) {
      boundingBoxOn(scene);
    }
  }, [bboxPresent]);

  // If the json has children, keep rendering

  if (passedJSON.children){
    return (
      <MapModels
        passedJSON={passedJSON} 
        anchor={anchor}
        scene={scene}
        measurementContainer={measurementContainer}
        texturesAllLoaded={texturesAllLoaded}
        controls={controls}
        canvasContainer={canvasContainer}
        setFunctionsAllLoaded={setFunctionsAllLoaded}
        functionsAllLoaded={functionsAllLoaded}
        setRotated={setRotated}
        rotated={rotated}
        reload={reload}
        setReload={setReload}
        //scene3dSortArr={scene3dSortArr}
        //setScene3dSortArr={setScene3dSortArr}
        />
    )
  }
};

export default Model;

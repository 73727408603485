import React from 'react';
import ReactDOM from 'react-dom';

export const excludeNodeEnv = () => {
  if (typeof document !== `undefined` && typeof window !== `undefined`)
    return true;
  return false;
};

export const measureLayerStyle = {
  display: 'inline-block',
  position: 'absolute',
  visibility: 'hidden',
  zIndex: -1,
};

export const measureElement = async (element, layerId = 'measure-layer') => {
  const domDocDefined = excludeNodeEnv();

  if (domDocDefined === true) {
    const measureLayer = await document.getElementById(layerId);

    const renderedElement = ReactDOM.render(element, measureLayer);

    const height = renderedElement.clientHeight;
    const width = renderedElement.clientWidth;

    // console.log(renderedElement)
    // console.log(height)

    ReactDOM.unmountComponentAtNode(measureLayer);

    return { height, width };
  }
};

export const cleanDrupalMarkup = (markup) => {
  if (markup) {
    let expression = new RegExp('<p>&nbsp;</p>', 'g');
    let result = markup.replace(expression, '');

    return result;
  }
};

export const attachProductBuilder = () => {
  const domDocDefined = excludeNodeEnv();

  if (domDocDefined === true) {
    let script = document.createElement('script');
    script.src = 'https://utilities.bocklighting.com/cocoon/dev/js/main.js';
    script.async = true;
    script.id = '_bockPb';

    let link = document.createElement('link');
    link.type = 'text/css';
    link.href = 'https://utilities.bocklighting.com/cocoon/dev/css/main.css';
    link.rel = 'stylesheet';
    link.id = '_bockPbStyles';

    document.body.appendChild(script);
    document.body.appendChild(link);
  }
};

export const detachProductBuilder = () => {
  const domDocDefined = excludeNodeEnv();

  if (domDocDefined === true) {
    const script1 = document.getElementById('_bockPb'),
      styles1 = document.getElementById('_bockPbStyles'),
      styles2 = document.getElementById('_bockPbCalcStyles'),
      div1 = document.getElementById('ar-ui-container');
    if (script1 !== null) script1.remove();
    if (styles1 !== null) styles1.remove();
    if (styles2 !== null) styles2.remove();
    if (div1 !== null) div1.remove();
  }
};

export const attachThreeJs = () => {
  const domDocDefined = excludeNodeEnv();

  if (domDocDefined === true) {
    let scriptDraco = document.createElement('script');
    scriptDraco.src =
      'https://utilities.bocklighting.com/js/draco_decoder.js?v=20190924';
    scriptDraco.async = true;
    scriptDraco.id = '_bockDraco';

    let scriptThree = document.createElement('script');
    scriptThree.src =
      'https://utilities.bocklighting.com/js/threejs.js?v=20190924';
    scriptThree.async = true;
    scriptThree.id = '_bockThree';

    document.body.appendChild(scriptDraco);
    document.body.appendChild(scriptThree);
  }
};

export const detatchThreeJs = () => {
  const domDocDefined = excludeNodeEnv();
  if (domDocDefined === true) {
    let scriptDraco = document.getElementById('_bockDraco');
    let scriptThree = document.getElementById('_bockThree');
    let divArContainer = document.getElementById('ar-ui-container');

    if (scriptThree !== null) {
      document.getElementById('_bockThree').remove();
    }
    if (scriptDraco !== null) {
      document.getElementById('_bockDraco').remove();
    }
    if (divArContainer !== null) {
      divArContainer.remove();
    }
  }
};

import {Box3} from 'three';

const loadTextures = (subModel, children) => {
  const mesh = subModel.current;
  const convert = 1000 / 25.4;

  // console.log('Load Texturess')

  if (mesh.type == 'Object3D' || mesh.type == 'Mesh' || mesh.type == 'Group') {
    mesh.traverse((node) => {
      if (node.isMesh) {
        // console.log('Load Texturess -  node.isMesh', node.material)
        if (['mat1', 'mat2', 'mat3'].some((a) => a === node.material.name)) {
          // console.log('Load Texturess -  if ([mat1, mat2, mat3].some', node.material)
          if (children.material != null) {
            // console.log('Load Texturess -  children.material', node.material)
            if (eval('children.material.' + node.material.name) != null) {
              var textureName = eval('children.material.' + node.material.name);

              // console.log('Load Texturess - eval(children.material)', node.material)

              // Assigning textures to materials from textures stored in window object
              if (window[textureName] != null) {
                var box = new Box3().setFromObject(node);
                var boxSize = Math.abs(box.max.x * box.max.y * box.max.z);
                boxSize = Math.cbrt(boxSize) * convert;

                var textureClone = window[textureName];
                var normalClone = window[textureName + '_normal'];

                // No texture assigned yet
                if (node.material.map === null){
                  node.material.map = textureClone;             
                  node.material.opacity = 0;
                  node.material.needsUpdate = true;
                // New texture is different to current texture
                } else if (node.material.map.name !== textureClone.name ){
                  node.material.map = textureClone;
                  node.material.opacity = 0;
                  node.material.needsUpdate = true;
                }
                // No normal map assginged yet
                if (normalClone != null  && node.material.normalMap == null) {
                  node.material.normalMap = normalClone;
                  node.material.needsUpdate = true;
                }
                // New normal map is ffifferernt
                else if (normalClone != null && node.material.normalMap !== normalClone ){
                  node.material.normalMap = normalClone
                  node.material.needsUpdate = true;
                }

                window[node.uuid] = node.material;
              }

              node.material.color.setHex(0xffffff);
            }
          }
        } else {
          // Adds material that are not named mat1, mat2 or mat3 to window object
          window[node.uuid] = node.material;
        }
      }
    });
  }
};

export default loadTextures;

import React from 'react';

export const roundUp = (num, dec) => {
  const d = Math.pow(10, dec || 0);
  return Math.ceil(num * d) / d;
};

export const crossCheckArray = (firstArr, secondArr) => {
  // if(secondArr.length === 0 ){
  //   return false;
  // }
  return firstArr.some((s) => secondArr.indexOf(s) >= 0);
};

export const createUniqueArrayNumerical = (
  parentArray = null,
  childArray,
  arrayKey
) => {
  let newArray = [];
  let returnArray = '';

  if (parentArray !== null) {
    parentArray.map((product) => {
      product[childArray]
        .filter(
          (item) =>
            item[arrayKey] !== null &&
            item[arrayKey] !== 0 &&
            item[arrayKey] !== undefined &&
            item[arrayKey] !== '' &&
            item[arrayKey] !== ' ' &&
            item[arrayKey] !== 9999 &&
            item[arrayKey] !== '9999' &&
            item[arrayKey].length !== 0
        )
        .map((item) => {
          if (Array.isArray(item[arrayKey])) {
            item[arrayKey].map((item, k) => {
              newArray.push(item);
            });
          } else {
            newArray.push(item[arrayKey]);
          }
        });
    });
    returnArray = new Set(newArray);
    returnArray = Array.from(returnArray);
    returnArray = returnArray.sort((a, b) => a - b);
  } else {
    // no parent
  }

  return returnArray;
};

// Returns an object of the min and max numbers in an array
export const minMaxFromArray = (array) => {
  let min = array[0],
    max = array[1],
    object = { min: min, max: max };
  return object;
};

// Returns the minimum number from an array of integers
export const minFromArray = (array) => {
  return Math.min(...array);
};

// Returns the maximum number from an array of integers
export const maxFromArray = (array) => {
  return Math.max(...array);
};

// 'id' param value should be the value for top-level items in the array
export const nest = (items, id = null, link = 'nodeParent') =>
  items
    .filter((item) => item[link] === id)
    .map((item) => ({ ...item, children: nest(items, item.taxonomyId, link) }));

export const nestToUnorderedList = (array, id = null) => {
  let nestIt = nest(array, id);

  return (
    <ul>
      {nestIt.map((item) => {
        return (
          <li>
            {item.name}
            {item.children.length > 0 &&
              nestToUnorderedList(item.children, item.id)}
          </li>
        );
      })}
    </ul>
  );
};

export const insertIntoArrayByIndex = (arr, index, newItem) => [
  ...arr.slice(0, index),
  newItem,
  ...arr.slice(index),
];

export const jsonToReconfig = (json) => {
  let baseJson = JSON.parse(json);

  let bgroup = '';
  let fkeys = Object.keys(baseJson)
    .filter((item) =>
      [
        'f',
        'fc1',
        'fc2',
        'a1',
        'a2',
        'l',
        'lk',
        'ld',
        'ls',
        'c',
        'cc',
        'w',
        'wc',
        'j',
        'm1',
        'm1c',
        'm2',
        'm2c',
        'm3',
        'm3c',
        'n',
      ].includes(item)
    )
    .map((item) => {
      let pnid2 = '';
      if (item == 'n') {
        pnid2 = 'node:' + baseJson[item] + ';cp:overview;';
      } else {
        pnid2 = item + ':' + baseJson[item] + ';';
      }
      bgroup += pnid2;
    });

  let reConfigBase = btoa(bgroup);

  return reConfigBase;
};

export const remoteImgExists = (imageSrc, successCallback, failCallback) => {
  var img = new Image();
  img.onload = successCallback;
  img.onerror = failCallback;
  img.src = imageSrc;
};

export const generateHash = () => {
  const math = Math.floor(10000000 + Math.random() * 90000000);
  return math;
};

import React from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import GatsbyLink from 'gatsby-link';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { navigate } from 'gatsby'

function ModalCartSuccess(props) {

  const {
    isOpen,
    currentItem,
    updateCartModal,
    cart
  } = props;


  const handleClose = () => {
    updateCartModal(false);
  };

  const handleClick = () => {
    handleClose()
    navigate('/cart')
  }


if(isOpen && currentItem && cart.items.length){

  let lastItem = cart.items[cart.items.length - 1];

  let image = lastItem.i;
  let title = lastItem.title;

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      className="ccnModalCartSuccess"
      scroll={"body"}
    >
      <DialogContent className="ccnModalCartSuccessInner">
        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close" style={{width: '50px', margin: '10px auto', position: 'absolute', top: '0', right: '10px', zIndex: '1'}}>
          <CloseIcon />
        </IconButton>
        <DialogContentText>
          <div class="row align-items-center">
            <div class="col-md-4">
              <img className={"w-100"} src={image} alt={title} />
            </div>
            <div class="col-md-8">
              <h3 className="">You've added {title} to your cart</h3>
            </div>
          </div>
          <div class="row mt-30">
            <div class="col-md-6">
              <a onClick={handleClose} className={'continue-btn w-100 text-center'}>Continue Shopping</a>
            </div>
            <div class="col-md-6">
              <a onClick={handleClick} className={'checkout-btn w-100 text-center'}>View Cart</a>
            </div>
          </div>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}
return null
}

export default ModalCartSuccess;

import * as THREE from 'three';
import React, {useRef, useState, Suspense, useEffect} from 'react';
import {Canvas} from 'react-three-fiber';
import {OrbitControls, PerspectiveCamera} from 'drei';
import JSONRebuilder from '../utils/JSONRebuilder';
import Model from './Model';
import Loading from './Loading';
import {Lighting,  Environment } from './Lighting';
import {LoadingManager} from 'three/src/loaders/LoadingManager';
import {TextureLoader} from 'three/src/loaders/TextureLoader';
import {RepeatWrapping, sRGBEncoding} from 'three';

// Rebuilds JSON Data with JSONRebuilder and passes the data into components
const ThreeConfigurator = ({ThreeJsJson, reload, setReload /* scene3dSortArr, setScene3dSortArr */ }) => {
  const textureManager = new LoadingManager();
  const textureLoader = new TextureLoader(textureManager);
  const [parsedJSONmodel, setparsedJSONmodel] = useState();
  const [parsedJSONtex, setparsedJSONtex] = useState();
  const [startModelsLoad, setStartModelsLoad] = useState(false);
  const [texturesAllLoaded, setTexturesAllLoaded] = useState(false);
  const [functionsAllLoaded, setFunctionsAllLoaded] = useState(false);
  const [savedRawJson, setSavedRawJson] = useState()
  const [isFirstJson, setIsFirstJson] = useState (true)
  const [loadHdriComplete, setLoadHdriComplete] = useState(false)
  const threeConfigMounted = useRef(true)
  const anchor = useRef();
  const guiContainer = useRef();
  const measurementContainer = useRef();
  const scene3dContainer = useRef();
  const perspCam = useRef();
  const controls = useRef();

  // Overlaying divs setup
  useEffect(() => {
    measurementContainer.current.style.position = 'absolute';
    measurementContainer.current.style.width = 'inherit';
    measurementContainer.current.style.height = 'inherit';
    measurementContainer.current.style.overflow = 'hidden';
    measurementContainer.current.style.zIndex = 1000;
    measurementContainer.current.style.pointerEvents = 'none';

    guiContainer.current.style.position = 'absolute';
    guiContainer.current.style.width = 'inherit';
    guiContainer.current.style.height = 'inherit';
    guiContainer.current.style.zIndex = 3;
    guiContainer.current.style.pointerEvents = 'none';

    scene3dContainer.current.style.display = 'inline-block';
    scene3dContainer.current.style.float = 'right';
    scene3dContainer.current.style.height = '100%';
    scene3dContainer.current.style.width = '100%';
    scene3dContainer.current.style.border = 'none';
  }, []);

  // Compores incoming data to saved data
  useEffect(() => {

      if (isFirstJson && savedRawJson === undefined) {
        setSavedRawJson(ThreeJsJson)
        setIsFirstJson(false)

      // If this the passed data is an update compared to the saved data
      }  else if ( !isFirstJson && ThreeJsJson !== savedRawJson){
        setSavedRawJson(ThreeJsJson)
      }
  },[ThreeJsJson])


  // If Data has been saved, the data is passed on
  useEffect(() => {
    if (savedRawJson){
      const newJSON = new JSONRebuilder(ThreeJsJson).getJSON();
      setparsedJSONmodel(newJSON);
      setparsedJSONtex(newJSON);
    }
  }, [savedRawJson]);

  // Check if threeConfig componant is mounted, reset states on unmount
  useEffect(() => {
    // console.log('Three config Mounted')
    THREE.Cache.enabled = true
    return () => {
      // console.log('Three config Umounted')
      threeConfigMounted.current = false;
      setTexturesAllLoaded(false)
      setSavedRawJson(undefined)
    }
  },[])

  // Start laoding of models and textures
  useEffect(() => {
    let initialLoad = false;
    if (parsedJSONtex && !initialLoad) {
      setStartModelsLoad(true);
      getTexturesUrl(parsedJSONtex, textureLoader, textureManager);
    }
    return () => {
      initialLoad = true;
      setTexturesAllLoaded(false);
      setStartModelsLoad(false);
    };
  }, [parsedJSONtex]);

  // Models loading
  useEffect(() => {
    if (startModelsLoad) {
      /*
      THREE.DefaultLoadingManager.onStart = (url, itemsLoaded, itemsTotal) => {
          console.log(
      'Started loading file: ' +
        url +
        '.\nLoaded ' +
        itemsLoaded +
        ' of ' +
        itemsTotal +
        ' files.'
        );
      };
      THREE.DefaultLoadingManager.onLoad = () => {
         console.log('Loading complete!')
         setModelsAllLoaded(true)
      };
      THREE.DefaultLoadingManager.onProgress = (
        url,
        itemsLoaded,
        itemsTotal
      ) => {
         console.log(
      'Loading file: ' +
        url +
        '.\nLoaded ' +
        itemsLoaded +
        ' of ' +
        itemsTotal +
        ' files.' +
        'Loading width' +
        (itemsLoaded / itemsTotal) * 100
        );
      };
      THREE.DefaultLoadingManager.onError = function (url) {
         console.log('There was an error loading ' + url);
      };
      */
    }
  }, [startModelsLoad]);

  // Get url from Json or fetching textures
  function getTexturesUrl(passedJSON, textureLoader, textureManager) {

    // Add Textures
    for (var name in passedJSON.materials) {
      let diffuseTexUrl = passedJSON.materials[name].url;
      let normalTexUrl = passedJSON.materials[name].normalUrl;

     // console.log('name', name)

      loadTexturesFromUrl(
        diffuseTexUrl,
        normalTexUrl,
        name,
        textureLoader,
        textureManager
      );

      textureManager.onLoad = () => {
        setTexturesAllLoaded(true)
      };

    }
  }

  // Loads textures from url and loads them onto window object
  function loadTexturesFromUrl(
    filePath,
    normalFilePath,
    name,
    textureLoader,
    textureManager
  ) {

      textureLoader.load(filePath, function (texture) {
        var textureMap = texture;
        textureMap.encoding = sRGBEncoding;
        textureMap.name = name;
        textureMap.flipY = false;
        textureMap.wrapS = RepeatWrapping;
        textureMap.wrapT = RepeatWrapping;
        textureMap.repeat.set(2, 2);
        // Textures added to window object to be accesed later by loadTexturesFromUrl
        window[textureMap.name] = textureMap;
      });

      textureLoader.load(normalFilePath, function (texture) {
        var normalMap = texture;
        normalMap.encoding = sRGBEncoding;
        normalMap.name = name + '_normal';
        normalMap.flipY = false;
        normalMap.wrapS = RepeatWrapping;
        normalMap.wrapT = RepeatWrapping;
        normalMap.repeat.set(2, 2);
        // Normal texture added to window object to be accesed later by loadTexturesFromUrl
        window[normalMap.name] = normalMap;
      });

      return () => {

      }

  }

  if (ThreeJsJson) {
    return (
      <div id="scene3d" ref={scene3dContainer}>
        <Loading functionsAllLoaded={functionsAllLoaded} />
        <div ref={guiContainer} id="gui-container"></div>
        <div ref={measurementContainer} id="measurement-container"></div>
       <Canvas
          //concurrent
          onCreated={({ gl, scene }) => {
            gl.toneMapping = THREE.ACESFilmicToneMapping
            gl.outputEncoding = THREE.sRGBEncoding
           // scene.background = new THREE.Color('#373737')
           // scene.background.convertSRGBToLinear()
          }}
          id={'canvas'}>
          <PerspectiveCamera ref={perspCam} makeDefault position={[0, 0, 13]}>
           {/*<Lighting />*/}
          </PerspectiveCamera>
          <Suspense fallback={null}>

            <object3D ref={anchor}>
              <Environment loadHdriComplete={loadHdriComplete} setLoadHdriComplete={setLoadHdriComplete} />
             {/*<Environment />*/}
              <Model
                anchor={anchor}
                measurementContainer={measurementContainer}
                passedJSON={parsedJSONmodel}
                scene3dContainer={scene3dContainer}
                texturesAllLoaded={texturesAllLoaded}
                controls={controls}
                setFunctionsAllLoaded={setFunctionsAllLoaded}
                functionsAllLoaded={functionsAllLoaded}
                loadHdriComplete={loadHdriComplete}
                reload={reload}
                setReload={setReload}
                // scene3dSortArr={scene3dSortArr}
                // setScene3dSortArr={setScene3dSortArr}
              />
            </object3D>
          </Suspense>
          <OrbitControls
            minDistance={6}
            maxDistance={30}
            ref={controls}
            screenSpacePanning
          />
        </Canvas>
      </div>
    );
  } else {
    return null
  }
};

export default ThreeConfigurator;

import React, { createContext } from 'react'
import ReactDOM from 'react-dom'
import Helmet from 'react-helmet'
import Alert from '../components/Commons/Alert'
import LoaderCenterHorizontal from '../components/Commons/LoaderCenterHorizontal'
import ModalProduct from '../components/Commons/ModalProduct'
import Modal3d from '../components/Commons/Modal3d'
import ModalCartSuccess from '../components/Commons/ModalCartSuccess'
import CartModal from '../components/Cart/CartModal'
import { chatWidget } from '../helpers/ChatWidget'
import { fullStory } from '../helpers/FullStory'
import { facebookPixel } from '../helpers/FacebookPixel'
import { navigate } from 'gatsby'
import {
  sessToken,
  getCart,
  myBock,
  myOrders,
  addToCart,
  updateCartItemQuantity,
  pushCartToCheckout,
  applyCoupon
} from '../helpers/DrupalHelper'
 import { measureLayerStyle } from '../helpers/DomHelper'

const StateContext = createContext({
  gridListing: false,
  modal: false,
  modalType: false,
  modalSiblings: null,
  modalContext: null,
  modalIndex: 0,
  updateModal: () => {},
  token: null,
  myBock: [],
  myOrders: [],
  cart: [],
  addToCart: () => {},
  updateCartItemQuantity: () => {},
  pushCartToCheckout: () => {},
  appliedDiscount: {
    valid: 0,
    code: null,
    type: null
  },
  applyCouponCode: () => {},
  loading: false,
  updateLoading: () => {},
  cartModal: {
    active: false,
    icon: null,
    title: null,
  },
  updateCartModal: () => {},
  lastToCart: null,
  alert: {
    active: false,
    variant: '',
    body: ''
  },
  updateAlert: () => {},
});

export class StateProvider extends React.Component {

  updateCartModal = async (active, icon = null, title = null) => {
    await this.setState({ cartModal: { active: active, icon: icon, title: title } });
  };

  updateLoading = (value) => {
    this.setState({ loading: value });
  };

  updateModal = (content, type = 'product', index = 0, siblings = null, additionalData = null) => {
    this.setState({ modal: content, modalType: type, modalSiblings: siblings, modalContext: additionalData, modalIndex: index });
  };

  updateGridListing = (value) => {
    if(value === 1){
      this.setState({ gridListing: true })
    } else {
      this.setState({ gridListing: false })
    }
  }

  addToCart = async ({
    handle: productHandle = null,
    sku: sku = null,
    quantity: quantity
  }) => {

    this.setState({ loading: true })

    let functionArgs, lastToCart

    functionArgs = {
      token: this.state.token,
      sku: sku,
      quantity: quantity
    }

    lastToCart = sku

    if(productHandle !== null) {
      functionArgs = {
        token: this.state.token,
        handle: productHandle,
        quantity: quantity
      }
      lastToCart = productHandle
    }

    await addToCart(functionArgs).then(async data => {
      this.setState({ cart: data })
    })
    await this.setState({ lastToCart: lastToCart })
    await this.updateCartModal(true)
    this.setState({ loading: false })
  }

  updateCartItemQuantity = async (cartItemId, finalQuantity) => {
    await updateCartItemQuantity(this.state.token, cartItemId, finalQuantity).then(async data => {
      this.setState({ cart: data })
    })
  }

  pushCartToCheckout = async () => {
    await pushCartToCheckout(this.state.token, this.state.appliedDiscount.code).then(async data => {
      navigate(data);
      // console.log(data)
    })
  }

  applyCouponCode = async (code = null) => {
    let response = null
    await applyCoupon(this.state.token, code).then(async data => {
      if (data.valid === 1) {
        this.setState({ appliedDiscount: data })
        this.updateAlert(`Coupon ${data.code} successfully applied`)
      } else {
        this.setState({ appliedDiscount: { valid: 0, code: null, type: null } })
        this.updateAlert(`Sorry, that coupon is invalid or expired`, `error`)
      }
      response = data
    })
    return response
  }

  updateAlert = (body = '', variant = 'success') => {
    if(body === '' || body === null || body === false) return this.setState({ alert: { active: false, body: body, variant: '' } })
    return this.setState({ alert: { active: true, body: body, variant: variant } })
  }

  state = {
    gridListing: false,
    modal: false,
    modalType: 'product',
    updateModal: this.updateModal,
    updateGridListing: this.updateGridListing,
    token: null,
    myBock: [],
    myOrders: [],
    cart: [],
    addToCart: this.addToCart,
    updateCartItemQuantity: this.updateCartItemQuantity,
    pushCartToCheckout: this.pushCartToCheckout,
    appliedDiscount: {
      valid: 0,
      code: null,
      type: null
    },
    applyCouponCode: this.applyCouponCode,
    loading: false,
    updateLoading: this.updateLoading,
    cartModal: {
      active: false,
      icon: null,
      title: null,
    },
    updateCartModal: this.updateCartModal,
    lastToCart: null,
    alert: {
      active: false,
      variant: '',
      body: ''
    },
    updateAlert: this.updateAlert,
  };

  async componentDidMount() {

    await sessToken().then(async data => {

      this.setState({token: data})

      await myBock(data).then(async data => {
        this.setState({myBock: data})

        if(data.isAuthenticated == true){
          await myOrders(data).then(data => {
            this.setState({myOrders: data})
          })
        }
      })

      await getCart(data).then(data => {
        this.setState({cart: data})
      })

    })

    await chatWidget(this.state.myBock)

    await fullStory(this.state.myBock, this.state.cart)

    await facebookPixel()

  }

  render() {
    return (
      <StateContext.Provider value={this.state}>
        <Helmet>
          <noscript>{`
            <img
              height="1"
              width="1"
              src="https://www.facebook.com/tr?id=225267912336825&ev=PageView&noscript=1"
              />
          `}</noscript>
        </Helmet>
        {this.state.modalType == 'product' && <ModalProduct state={this.state} updateModal={this.updateModal} />}
        {this.state.modalType == 'three' && <Modal3d state={this.state} updateModal={this.updateModal} updateLoading={this.updateLoading} />}
        {this.props.children}
        {this.state.loading === true && <LoaderCenterHorizontal full />}
        {/*{this.state.cartModal === true && <ModalCartSuccess state={this.state} cart={this.state.cart} isOpen={this.state.cartModal} currentItem={this.state.lastToCart} updateCartModal={this.updateCartModal} />}*/}
        {this.state.cartModal.active === true && <CartModal cartModal={this.state.cartModal} currentItem={this.state.lastToCart} />}
        <Alert state={this.state.alert} updateAlert={this.updateAlert} />
        <div id="measure-layer" style={measureLayerStyle} />
      </StateContext.Provider>
    );
  }
}

export default StateContext

export const StateConsumer = StateContext.Consumer;

import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

function LoaderCenterHorizontal(props) {

  const {
    full,
    inline,
    inlineOverlay
  } = props;

  let color = '#222'

  if(props.color){
    color = props.color
  }

  if(full) {
    return (
      <>
        <div style={{
          margin: '0 auto',
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 999998,
          backgroundColor: 'rgba(255,255,255,.92)',
          width: '100%'
        }}></div>
        <div style={{
          margin: '0 auto',
          position: 'fixed',
          top: '50%',
          left: 0,
          right: 0,
          textAlign: 'center',
          zIndex: 999999,
          width: '100%'
        }}>
          <CircularProgress thickness={1.3} />
        </div>
      </>
    )
  } else if(inline){
    return (
      <div style={{
        margin: 'auto',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        textAlign: 'center',
        zIndex: 999999,
        width: '100%',
        height: '100%'
      }}>
        <CircularProgress thickness={1.3} style={{
          top: 0,
          color: color,
          bottom: 0,
          position: 'absolute',
          margin: 'auto',
          left: 0,
          right: 0,
          width: '100%',
          height: '100%',
          maxWidth: 40,
          maxHeight: 40,
        }}/>
      </div>
    )
  } else if(inlineOverlay){
    return (
      <div style={{
        margin: 'auto',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        textAlign: 'center',
        zIndex: 999999,
        width: '100%',
        height: '100%',
        background: 'rgba(255,255,255,.9)'
      }}>
        <CircularProgress thickness={1.3} style={{
          top: 0,
          color: color,
          bottom: 0,
          position: 'absolute',
          margin: 'auto',
          left: 0,
          right: 0,
          width: '100%',
          height: '100%',
          maxWidth: 40,
          maxHeight: 40,
        }}/>
      </div>
    )
  }
  return (
    <div style={{
      margin: '0 auto',
      position: 'absolute',
      top: '50%',
      left: 0,
      right: 0,
      textAlign: 'center',
      zIndex: 999999,
      width: '100%'
    }}>
      <CircularProgress thickness={1.3} style={{color: color}}/>
    </div>
  )
}

export default LoaderCenterHorizontal;

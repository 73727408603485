import React from 'react';
import { Flex } from 'rebass';

const NoImage = props => {
  return (
    <Flex {...props} justifyContent="center" alignItems={`center`}>
      <i class="lar la-image" style={{fontSize: props.size,color: `rgba(0,0,0,.4)`}}></i>
    </Flex>
  );
};

export default NoImage;

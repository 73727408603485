import React from 'react'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser'
import Img from "gatsby-image"
import GatsbyLink from 'gatsby-link'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import ConfigureActions from '../Product/ConfigureActions'
import SliderArrow from './SliderArrow'
import Slider from "react-slick"
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import SocialShare from './SocialShare'
import ProductMeta from '../Product/ProductMeta'
import ReadMore from './ReadMore'
import { navigate } from 'gatsby'
import { cleanDrupalMarkup } from '../../helpers/DomHelper'
function ModalProduct(props) {

  const modal = props.state.modal;

  const images = modal.imageGroup;

  const settings = {
    customPaging: function(i) {
      return (
        <a>
          <Img fluid={images[i].childImageSharp.fluid} alt={modal.nodeName} />
          {/*<img src={images[i]} />*/}
        </a>
      );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb productImgSliderDeck--bottom",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SliderArrow buttonIcon="dlicon arrows-1_tail-triangle-right" />,
    prevArrow: <SliderArrow buttonIcon="dlicon arrows-1_tail-triangle-left" />
    // className: "product-dec-right product-tabs pro-dec-big-img-slider-2"
  };


  const handleClose = () => {
    props.updateModal(false);
  }

  const gotoProduct = () => {
    navigate(modal.pageUrl)
    handleClose()
  }

if(modal){
  return (

    <Dialog
      open={modal}
      onClose={handleClose}
      className="ccnModalProduct"
      scroll={"body"}
    >




      <DialogContent className="ccnModalProductInner">


      <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close" style={{width: '50px', margin: '10px auto', position: 'absolute', top: '0', right: '10px', zIndex: '1'}}>
        <CloseIcon />
      </IconButton>


                        <div class="row">
                            <div class="col-lg-6 col-sm-12 col-xs-12">
                              <Slider {...settings}>
                                {images && images.map((image, k) =>
                                  {
                                    return(
                                      <div class="ccn-slick-slide" key={k}>
                                        <Img fluid={image.childImageSharp.fluid} alt={modal.nodeName} />
                                        {/*<img src={image} />*/}
                                      </div>
                                    )
                                  }
                                )}
                              </Slider>

                            </div>
                            <div class="col-lg-6 col-sm-12 col-xs-12">
                                <div class="product-details-content quickview-content">
                                    <h2 onClick={(v) => gotoProduct()}>{modal.nodeName}</h2>
                                    {/*<div class="product-rating-stock">
                                        <div class="product-dec-rating-reviews">
                                            <div class="product-dec-reviews">
                                                <a> (3 customer reviews)</a>
                                            </div>
                                        </div>
                                        <div class="pro-stock">
                                            <span><i class="dlicon ui-1_check-circle-08"></i>in stock</span>
                                        </div>
                                    </div>*/}
                                    {/*<p class="product-price product-details-price"><span class="main-price discounted"><del>£723.00</del></span> <span class="discounted-price">£672.00</span></p>*/}
                                    <ReadMore height="160" color="#fff" title="Read More">{ReactHtmlParser(cleanDrupalMarkup(modal.nodeBody))}</ReadMore>


                                    <div class="pro-details-size-color">
                                      <ConfigureActions array={modal.nodePbItems} arrayItemKey={'key'} arrayItemTitle={'_n'} productId={modal.nodeId} />
                                    </div>

                                    {/*<div class="pro-details-quality">
                                        <div class="quantity quantity--2">
                                            <input type="text" class="quantity-input" name="qty" id="qty-4" value="1"/>
                                            <div class="dec qtybutton">-</div>
                                            <div class="inc qtybutton">+</div>
                                        </div>
                                        <div class="pro-details-cart btn-hover">
                                            <a href="#">Add To Cart</a>
                                        </div>
                                        <div class="pro-details-wishlist">
                                            <a href="#"><i class="fa fa-heart-o"></i></a>
                                        </div>
                                        <div class="pro-details-compare">
                                            <a href="#"><i class="fa fa-files-o"></i></a>
                                        </div>
                                    </div>*/}
                                    {/*<div class="pro-details-sku">
                                        <span>SKU: D-12525</span>
                                    </div>
                                    <div class="pro-details-meta">
                                        <span>Categories : </span>
                                        {modal.categories &&

                                            <ul>
                                            {modal.categories.map((category, index) => {
                                              return (
                                                <li><GatsbyLink to={category.alias}>{category.title}</GatsbyLink></li>
                                              )
                                            })}
                                            </ul>
                                          }






                                    </div> */}
{/*                                    <div class="pro-details-meta">
                                        <span>Tag : </span>
                                        <ul>
                                            <li><a href="#">Furniture,</a></li>
                                            <li><a href="#">Fashion</a></li>
                                        </ul>
                                    </div> */}
                                    <ProductMeta />
                                    <SocialShare
                                      url={modal.alias}
                                      title={'Custom Build ' + modal.part_number}
                                    />



                                </div>
                            </div>
                        </div>

      </DialogContent>

    </Dialog>


  )
}
return null
}

export default ModalProduct;

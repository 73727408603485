import React, {useRef, useState, Suspense, useEffect, memo} from 'react';
import {useFrame, useThree} from 'react-three-fiber';
import {useSpring, a} from 'react-spring/three';
import * as THREE from 'three';
import load3DAssets from '../utils/load3DAssets';
import '../utils/loadTextures';
import loadTextures from '../utils/loadTextures';
import removeTextures from '../utils/removeTextures';

// Loads model parts with GLTFLoader from url
// Positions each sub model part and sets textures from using the passed in json data
const SubModel = ({
  anchor,
  child,
  modelURL,
  childObj,
  scene,
  measurementContainer,
  texturesAllLoaded,
  passedJSON,
  controls,
  canvasContainer,
  setFunctionsAllLoaded,
  functionsAllLoaded,
  setRotated,
  rotated,
  modelLoad
}) => {
  const convertToInches = 1000 / 25.4;
  const [gltf, setGltf] = useState();
  const [model, setModel] = useState();
  const {camera} = useThree();
  const subModel = useRef();
  const [savedJson, setSavedJson] = useState();
  const [subModelLoaded, setSubModelLoaded] = useState(false);

  // Translation useSpring animaiton setup
  let [{x}, setX] = useSpring(() => ({
    x: passedJSON.children[child].x / convertToInches,
  }));
  let [{y}, setY] = useSpring(() => ({
    y: passedJSON.children[child].y / convertToInches,
  }));
  let [{z}, setZ] = useSpring(() => ({
    z: passedJSON.children[child].z / convertToInches,
  }));

  // Rotation useSpring animaiton setup
  let [{_x}, setRx] = useSpring(() => ({
    _x: THREE.Math.degToRad(passedJSON.children[child].rx) * 2,
  }));
  let [{_y}, setRy] = useSpring(() => ({
    _y: THREE.Math.degToRad(passedJSON.children[child].ry),
  }));
  let [{_z}, setRz] = useSpring(() => ({
    _z: THREE.Math.degToRad(passedJSON.children[child].rz),
  }));
  
  useEffect(()=>{
    if (modelLoad.scene.children[0]){
      setGltf(modelLoad);
    }
  },[modelLoad])

  // Mount check
  useEffect(()=>{
    // console.log('Mounted SubModel', childObj.type)
    return () =>{
      // console.log( 'Umounted SubModel', childObj.type)
      setModel(undefined)
      setGltf(undefined)
      setSavedJson(undefined)
      modelLoad = undefined
    }
  },[])

  // Clone submodel duplicates
  useEffect(() => {
    if (gltf !== undefined){
      if ( gltf.scene.children[0] !== undefined) {
        if (child.slice(-1) !== '0') {
          if (model === undefined) {
            let clone = gltf.scene.children[0].clone();
            setModel(clone);
          }
        } else {
          if (model === undefined) {
            setModel(gltf.scene.children[0]);
          }
        }
      }
    }
  },[gltf])

  // Set initial models
  useEffect(() => {

    if (/*savedJson === undefined && */ model !== undefined ) {
      new load3DAssets(
        subModel,
        childObj,
        anchor,
        true,
        0,
        scene,
        measurementContainer,
        controls,
        camera,
        canvasContainer,
        setFunctionsAllLoaded,
        functionsAllLoaded,
        passedJSON,
        setSubModelLoaded
        );
      }
    return () => {
      setSubModelLoaded(false);
    };
  }, [model]);

  // Save passed Json
  useEffect(() => {
    if (subModelLoaded){
        setSavedJson(passedJSON);
    }
  }, [subModelLoaded]);

  // Texture to animate
  useFrame(() => {
     if ( subModel.current){
      const mesh = subModel.current;
        mesh.traverse((node) => {
          if (node.isMesh) {
            if (node.material){
              if (node.material.opacity < 1){
                node.material.opacity = node.material.opacity + 0.01
                if (node.material.opacity >= 1){
                }
              }
            }         
          }
        });
     }
  })  

  // Animate submodels from json updates
  useEffect(() => {

    if (passedJSON && savedJson && subModel.current) {    
      
        switch (true) {            
        // X translation
        case  subModel.current && passedJSON.children[child].x !== savedJson.children[child].x:
          setX({x: passedJSON.children[child].x / convertToInches});
          setSavedJson(passedJSON);

        // Y translation
        case subModel.current && passedJSON.children[child].y !== savedJson.children[child].y:
          setY({y: passedJSON.children[child].y / convertToInches});
          setSavedJson(passedJSON);

        // Z translation
        case subModel.current && passedJSON.children[child].z !== savedJson.children[child].z:
          setZ({z: passedJSON.children[child].z / convertToInches});
          setSavedJson(passedJSON);

        // X rotation
        case subModel.current && passedJSON.children[child].rx !== savedJson.children[child].rx &&
          !rotated:
          setRx({_x: THREE.Math.degToRad(passedJSON.children[child].rx)});
          setSavedJson(passedJSON);
          setRotated(true);

        // Y rotation
        case subModel.current && passedJSON.children[child].ry !== savedJson.children[child].ry &&
          !rotated:
          setRy({_y: THREE.Math.degToRad(passedJSON.children[child].ry)});
          setSavedJson(passedJSON);
          setRotated(true);

        // Z rotation
        case subModel.current && passedJSON.children[child].rz !== savedJson.children[child].rz &&
          !rotated:
          setRz({_z: THREE.Math.degToRad(passedJSON.children[child].rz)});
          setSavedJson(passedJSON);
          setRotated(true);

        // Remove texture 
        case subModel.current && Object.keys(childObj.material).length < Object.keys(savedJson.children[childObj.type].material).length:     
          for (var matName in savedJson.children[childObj.type].material ){
              if (!childObj.material.hasOwnProperty(matName)){
              removeTextures(subModel, childObj, matName ); 
            }
          }
          setSavedJson(passedJSON);   

      }   
      
    }
  }, [passedJSON]);
  
  // Texture updates from new Json entries
  useEffect(() => {
    if (passedJSON && savedJson && texturesAllLoaded && model) {
      loadTextures(subModel, childObj);
      setSavedJson(passedJSON);
    }
  }, [texturesAllLoaded]);

  if (model) {
    return (
      <a.primitive
        ref={subModel}
        object={model}
        position-x={x}
        position-y={z}
        position-z={y}
        rotation-x={_x}
        rotation-y={_z}
        rotation-z={_y}
      />
    );
  } else {
    return null;
  }
};

// export default memo(SubModel)
export default SubModel
import React, { useState, useEffect } from 'react'
import { Flex, Box, Image, Text } from 'rebass'
import ProductCounter from './ProductCounter'
import NoImage from '../Commons/Icons/NoImage'
import RemoveItemIcon from '../Commons/Icons/RemoveItem'
// import Divider from './Divider'
import LoaderCenterHorizontal from '../Commons/LoaderCenterHorizontal'
const LineItem = props => {

  const {
    cartItem,
    decreaseProductAmount,
    increaseProductAmount,
    removeItemFromCart
  } = props;

  const { sku, title, quantity, price, total, id, i } = cartItem;

  const [isLoading, setIsLoading] = useState(false)

  const removeItem = async ({ id }) => {
    await setIsLoading(true)
    await removeItemFromCart({ id })
    setIsLoading(false)
  }

  const increaseQuantity = async ({ id, quantity }) => {
    await setIsLoading(true)
    await increaseProductAmount({ id, quantity })
    setIsLoading(false)
  }

  const decreaseQuantity = async ({ id, quantity }) => {
    await setIsLoading(true)
    await decreaseProductAmount({ id, quantity })
    setIsLoading(false)
  }

  let imageSrc = '';
  if(i !== null){
    imageSrc = i;
  }

  const displayPrice = 'null';

  return (
    <React.Fragment>
      <Flex
        flexDirection={['column', 'row']}
        alignItems="center"
        style={{
          fontSize: 13,
          border: '1px solid #ebecec',
          boxShadow: '0 3px 2px rgba(0,0,0,.015)',
          background: '#fff',
          padding: '10px 10px',
          transition: 'all 1s ease',
          position: 'relative'
        }}
        // p={[1, 2, 2]}
        {...props}
      >
      {isLoading && <LoaderCenterHorizontal inlineOverlay />}
      <Flex
        width={[1, 1 / 20]}
        display={['none', 'block']}
        justifyContent={['center', 'center']}
        alignSelf={['center']}
        alignItems={['center']}
      >
        <Box display={['none', 'block']}
          onClick={() => removeItem({ id })}>
          <RemoveItemIcon
            display={['none', 'block']}
            ml={[1, 4]}
            aria-hidden
            size={[16, 18, 20]}
            sx={{ cursor: 'pointer', height: 'auto' }}
          />
        </Box>
      </Flex>

        <Flex
          width={[1, 2 / 10, 1 / 10]}
          p={[1, 1]}
          justifyContent={['center', 'flex-start']}
        >
          {imageSrc ? (
            <Image
              src={imageSrc}
              alt={title}
              style={{alignSelf: 'center'}}
            />
          ) : (
            <NoImage
              size={22}
              p={4}
            />
          )}
        </Flex>

        <Flex
          width={[1, 4 / 10, 5 / 10]}
          p={[1, 3]}
          justifyContent={['center', 'flex-start']}
          style={{textTransform: `uppercase`, fontWeight: 600}}
        >
          <Box>
            <Text>{title}</Text>
          </Box>
        </Flex>

        <Flex
          width={[1, 1 / 10]}
          p={[1, 3]}
          justifyContent={['center', 'flex-start']}
          alignSelf={['center']}
        >
          <Text>
            {price}
          </Text>
        </Flex>
        <Flex
          width={[1, 2 / 10]}
          p={[1, 3]}
          justifyContent={['center', 'flex-start']}
          alignSelf={['center']}
        >
          <Box width={[1 / 5, 1]}>
            <ProductCounter
              currentAmount={quantity}
              decreaseAmount={() => decreaseQuantity({ id, quantity })}
              increaseAmount={() => increaseQuantity({ id, quantity })}
            />
          </Box>
        </Flex>

        <Flex
          width={[1, 1 / 10]}
          p={[1, 3]}
          justifyContent={['center', 'flex-start']}
          alignSelf={['center']}
        >
          <Box width={[1 / 5, 1]}>
            <Text>{total}</Text>
          </Box>
        </Flex>




      </Flex>
    </React.Fragment>
  );
};

export default LineItem;

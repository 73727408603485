import React, { useState, useContext } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Grid from '@material-ui/core/Grid'
import { Flex, Box, Image, Text } from 'rebass'
import StateContext from '../../context/StateContext'
import LineItem from './LineItem'
import LoaderCenterHorizontal from '../Commons/LoaderCenterHorizontal'

const Cart = props => {

  const {
    cart,
    updateCartItemQuantity
   } = useContext(StateContext)

  if(cart && cart.items && cart.items.length > 0) {

    async function decreaseProductAmount({ id, quantity }) {
      if (quantity === 1) return;
      let newQuantity = quantity - 1;
      try {
        await updateCartItemQuantity(id, newQuantity);
      } catch (error) {
        console.error(error);
      }
    }

    async function increaseProductAmount({ id, quantity }) {
      let newQuantity = quantity + 1;
      try {
        await updateCartItemQuantity(id, newQuantity);
      } catch (error) {
        console.error(error);
      }
    }

    async function removeItemFromCart({ id }) {
      let newQuantity = parseInt(0);
      try {
        await updateCartItemQuantity(id, newQuantity);
      } catch (error) {
        console.error(error);
      }
    }

    return (
      <>
        <Flex
          flexDirection={['column', 'row']}
          alignItems="top"
          className="cartTableHead"
          fontSize={13}
          backgroundColor={`#eee`}
          color={`rgba(0,0,0,.5)`}
          mb={3}
          >


            <Flex
            width={[1, 1 / 20]}
            display={['none', 'block']}
            justifyContent={['center', 'flex-start']}
            >

            </Flex>
            <Flex
            width={[1, 2 / 10, 1 / 9]}
            p={[1, 3]}
            justifyContent={['center', 'flex-start']}
            >

            </Flex>
            <Flex
            width={[1, 4 / 10, 5 / 10]}
            p={[1, 3]}
            justifyContent={['center', 'flex-start']}
            >
              Product
            </Flex>
            <Flex width={[1, 1 / 10]}
            p={[1, 3]}
            justifyContent={['center', 'flex-start']}>
              Price
            </Flex>
            <Flex width={[1, 2 / 10]}
            p={[1, 3]}
            justifyContent={['center', 'flex-start']}>
              Quantity
            </Flex>
            <Flex width={[1, 1 / 10]}
            p={[1, 3]}
            justifyContent={['center', 'flex-start']}>
              Total
            </Flex>

          </Flex>



          {cart.items.map((item, key) => (
            <React.Fragment>
              <LineItem
                key={key}
                cartItem={item}
                decreaseProductAmount={decreaseProductAmount}
                increaseProductAmount={increaseProductAmount}
                removeItemFromCart={removeItemFromCart}
                mb={[4, 15]}
              />
            </React.Fragment>
        ))}
      </>
    )
  }

  if(cart && cart.items && cart.items.length === 0) {
    return (
      <Box position={`relative`} textAlign={`center`}>
        Your cart is empty
      </Box>
    )
  }
  return (
    <Box position={`relative`}>
      <LoaderCenterHorizontal inline />
    </Box>
  )
}

export default Cart

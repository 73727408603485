import React, { useState, useRef, useEffect } from "react";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { makeStyles } from "@material-ui/core";
import { excludeNodeEnv, measureElement } from '../../helpers/DomHelper'

const useStyles = makeStyles({
  visibilityMagic: {
    maxHeight: props => `${typeof(props.height) !== 'undefined' ? props.height : '70'}px`,
    "&:before": {
      content: '""',
      background: props => `linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, ${props.color} 100%)`,
      height: '40px',
      position: 'absolute',
      left: '0',
      right: '0',
      bottom: '0',
    }
  },
  forward: {
    fontSize: '13px',
    fontWeight: '600',
    color: 'rgba(0, 0, 0, .4)',
    paddingTop: '10px',
    "&:after": {
      content: '"\u2192"',
      position: 'relative',
      top: 0,
      paddingLeft: '5px',
    }
  },
  back: {
    fontSize: '13px',
    fontWeight: '600',
    color: 'rgba(0, 0, 0, .4)',
    paddingTop: '10px',
    "&:after": {
      content: '"\u2192"',
      position: 'relative',
      top: 0,
      paddingLeft: '5px',
      transform: 'rotate(180deg)',
      float: 'left',
    }
  }
});



export default function ReadMore(props) {

  const classes = useStyles(props);

  const {
    title,
    color,
    height
  } = props

  let layerHeight = 70

  if(typeof height !== 'undefined') layerHeight = parseInt(height) * 1

  const [expanded, setExpanded] = React.useState(false);
  const [elHeight, setElHeight] = useState(null)

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  }

  const getHeight = async () => {
    const measureEl = await measureElement(<div>{props.children}</div>)
    setElHeight(measureEl.height)
  }

  useEffect(() => {
    getHeight()
  }, [props.children]);

  if(excludeNodeEnv() === false) {
    return (
      <div className="exp-readmore">
       <ExpansionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
         <ExpansionPanelSummary
           aria-controls="panel1a-content"
           id="panel1a-header"
         >
         {(expanded !== false) ? <div  class={classes.back}>Close</div> : <div className={classes.forward}>{props.title}</div>}
         </ExpansionPanelSummary>
         <ExpansionPanelDetails className={classes.visibilityMagic}>
           <div className="exp-readmore-content">{props.children}</div>
         </ExpansionPanelDetails>
       </ExpansionPanel>
     </div>
    )
  }
  if(elHeight > layerHeight) {

    return (
      <div className="exp-readmore">
       <ExpansionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
         <ExpansionPanelSummary
           aria-controls="panel1a-content"
           id="panel1a-header"
         >
         {(expanded !== false) ? <div  class={classes.back}>Close</div> : <div className={classes.forward}>{props.title}</div>}
         </ExpansionPanelSummary>
         <ExpansionPanelDetails className={classes.visibilityMagic}>
           <div className="exp-readmore-content">{props.children}</div>
         </ExpansionPanelDetails>
       </ExpansionPanel>
     </div>
    );
  }
  return (
    <div className="exp-readmore-content">{props.children}</div>
  )
}

import React from 'react';
import { StateProvider } from './src/context/StateContext';
import ProductProvider from './src/context/Product/ProductProvider';
import './src/assets/global-css/vendor/bootstrap.min.css';
import './src/assets/global-css/vendor/iconfont.min.css';
import './src/assets/CocoonFlaticon/ccn-flaticon.css';
import './src/assets/BockFlatIcon/flaticon.css';
import './src/assets/global-css/plugins/modal-video.min.css';
import './src/assets/global-css/vendor/helper.css';
import './src/assets/global-css/plugins/plugins.css';
import './src/assets/global-css/vendor/revslider.css';
import './src/assets/global-css/global-styles.css';

export const wrapPageElement = ({ element, props }) => (
  <StateProvider {...props}>
    <ProductProvider {...props}>{element}</ProductProvider>
  </StateProvider>
);
